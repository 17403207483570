import {useParams} from "react-router";
import React,{useRef, useState, useEffect, } from 'react';


// import '../assets/vendors/mdi/css/materialdesignicons.min.css'


import mtn from '../../../assets/images/icons/mtn.png'
import airtel from '../../../assets/images/icons/airtel-logo.png'
import card from '../../../assets/images/icons/visa-mastercard.png'
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import NumberFormat from "react-number-format";
import Swal from 'sweetalert2'
import Helmet from "react-helmet";
import MetaTags from 'react-meta-tags';

export default ({change_state,change_sublink})=>{

    change_state('deposit_funds');
    change_sublink('list_accounts');

    const [CardUrl,setCardUrl] = useState(null);
    const [CardResponse,setCardResponse] = useState(null);
    const [AirtelResponse,setAirtelResponse] = useState(null);
    const [mtnResponse,setmtnResponse] = useState(null);
    const [mtndata,setMtnData] = useState({});
    const [Airteldata,setAirtelData] = useState({});
    const [Carddata,setCardData] = useState({});
    const [sending,setSending] = useState(false);
    const [sending_card,setsending_card] = useState(false);
    const [sending_airtel,setsending_airtel] = useState(false);
    const [sending_mtn,setsending_mtn] = useState(false);
    const [error,setError] = useState([]);

    const [cardPanel,setCardPanel] = useState(-1);
    const [airtelPanel,setAirtelPanel] = useState(-1);
    const [mtnPanel,setMtnPanel] = useState(-1);
    const [pageData,setPageData] = useState({});
    const [isValid,sIsValid] = useState(false);
    const {page} = useParams();




    const process_mtn = e => {
        e.preventDefault()


        console.log(JSON.stringify({ mtndata }));

        setmtnResponse('Your request has been submitted. Please wait for a mobile money PIN request to proceed or dial *165# to approve this payment');

        setsending_mtn(true);


        fetch(utils.base_url+'api/deposit_funds/'+ReactSession.get('id'), {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ mtndata }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setsending_mtn(false);
                // setmtnResponse('Your request is in progress. Dial *165# to approve this transaction or try again');
                setmtnResponse(json.ResultMessage);


                if(parseInt(json.ResultCode) === 10001)
                {


                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'success',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })


                    setError({
                        icon:'bell',
                        header:'Thank you',
                        class_Name:'success',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });

                   // e.target.reset();


                    //setMtnData({})

                }else{


                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })

                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                 //   e.target.reset();


                  //  setMtnData({})
                }



                //setUser(json.user)

            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    const process_airtel = e => {
        e.preventDefault()


        console.log(JSON.stringify({ Airteldata }));

        setAirtelResponse('Your request has been submitted');

        setsending_airtel(true);


        fetch(utils.base_url+'api/deposit_funds_airtel/'+ReactSession.get('id'), {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ Airteldata }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setsending_airtel(false);
                // setmtnResponse('Your request is in progress. Dial *165# to approve this transaction or try again');
                setAirtelResponse(json.ResultMessage);


                if(parseInt(json.ResultCode) === 10001)
                {



                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'success',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })



                    setError({
                        icon:'bell',
                        header:'Thank you',
                        class_Name:'success',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });

                 //   e.target.reset();


                 //   setAirtelData({})

                }else{




                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                 //   e.target.reset();


                 //   setAirtelData({})
                }



                //setUser(json.user)

            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };



    console.log(page);
    useEffect(()=>{




    },[]);

    return (
        <div>


            {
            <div>




                <div className="col-lg-12 stretch-card">
                    <div className="card">
                        <div className="card-body ">



                            <div className="email-head">
                                <div className="email-head-title d-flex align-items-center">
                                        Deposit Funds
                                </div>
                            </div>

                                    <div className="row profile-body">

                                        <div className="col-md-12 middle-wrapper">
                                            <div className="row">
                                                <div className="col-md-12 grid-margin">
                                                    <div >




                                                        <div className="card-body">
                                                            <p className="mb-3 tx-14">{pageData.bio}</p>

                                                            {
                                                                mtnResponse && <div className="alert alert-warning text-center" role="alert">
                                                                    {mtnResponse}
                                                                </div>
                                                            }

                                                            <div >
                                                                <div id="accordion" className="accordion" role="tablist">



                                                                    <div className="card">
                                                                        <div className="card-header" role="tab" id="headingOne">
                                                                            <h6 className="mb-0">
                                                                                <span data-toggle="collapse" style={{cursor:'pointer'}} onClick={()=>{

                                                                                    setMtnPanel(parseInt(mtnPanel)*-1);
                                                                                    setAirtelPanel(-1);
                                                                                    setCardPanel(-1);

                                                                                }
                                                                                } aria-expanded="true" aria-controls="collapseOne" className>


                                                                                  <img className="img-fluid mr-3" src={mtn} alt="" width={"50"} />    MTN Mobile Money
                                                                                </span>
                                                                            </h6>
                                                                        </div>
                                                                        <div id="collapseOne" className={mtnPanel > 0?"collapse show":"collapse"}  role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" style={{}}>
                                                                            <div className="card-body">




                                                                                <form className="forms-sample" onSubmit={process_mtn} >

                                                                                    <div className="email-compose-fields" data-select2-id={14}>














                                                                                        <div className="description mt-3">
                                                                                            <div className="form-group row py-0">
                                                                                                <label className="col-md-2 control-label">Mobile</label>
                                                                                                <div className="col-md-10">




                                                                                                    <NumberFormat
                                                                                                        required={"required"}
                                                                                                        className={" form-control "}
                                                                                                        placeholder="Your Phone number e.g 0773956277"
                                                                                                        value={mtndata.phone}
                                                                                                        onChange={(e)=>setMtnData({...mtndata,phone:e.target.value})}

                                                                                                        format="#### ### ###" />



                                                                                                </div>
                                                                                            </div>
                                                                                        </div>







                                                                                        <div className="description mt-3">
                                                                                            <div className="form-group row py-0">
                                                                                                <label className="col-md-2 control-label">Amount</label>
                                                                                                <div className="col-md-10">




                                                                                                    <NumberFormat
                                                                                                        required={"required"}
                                                                                                        className={" form-control "}
                                                                                                        placeholder="Enter amount here"
                                                                                                        value={mtndata.amount}
                                                                                                        onChange={(e)=>setMtnData({...mtndata,amount:e.target.value})}
                                                                                                        thousandSeparator={','}
                                                                                                        suffix={'/-'}
                                                                                                    />




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>






                                                                                    </div>


                                                                                    <div className="form-group row pr-2 pl-2">

                                                                                        <div className="col-md-2">
                                                                                        </div>

                                                                                        <div className="col-md-10">
                                                                                            <div className="form-group mb-0">






                                                                                                {

                                                                                                    !sending_mtn ?
                                                                                                        <button type={"submit"} className="btn btn-success  btn-space btn-block">Pay</button>

                                                                                                        :
                                                                                                        <button type={"button"} disabled={true} className="btn  btn-success btn-space btn-block">   Authorizing....</button>

                                                                                                }



                                                                                            </div>
                                                                                        </div>


                                                                                    </div>

                                                                                </form>


                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        AirtelResponse && <div className="alert alert-danger text-center" role="alert">
                                                                            {AirtelResponse}
                                                                        </div>
                                                                    }


                                                                    <div className="card">
                                                                        <div className="card-header" role="tab" id="headingOne">
                                                                            <h6 className="mb-0">
                                                                                <span data-toggle="collapse" style={{cursor:'pointer'}} onClick={()=>{

                                                                                    setAirtelPanel(parseInt(airtelPanel)*-1);
                                                                                    setMtnPanel(-1);
                                                                                    setCardPanel(-1);
                                                                                }
                                                                                } aria-expanded="true" aria-controls="collapseOne" className>


                                                                                  <img className="img-fluid mr-3" src={airtel} alt="" width={"50"} />    Airtel Money
                                                                                </span>
                                                                            </h6>
                                                                        </div>
                                                                        <div id="collapseOne" className={airtelPanel > 0?"collapse show":"collapse"}  role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" style={{}}>
                                                                            <div className="card-body">



                                                                                {
                                                                                   ! AirtelResponse &&
                                                                                   <div className="alert alert-danger text-center" role="alert">
                                                                                     Dial *185*3# to initiate a transaction withdraw from your mobile wallet
                                                                                    </div>
                                                                                }



                                                                                <form className="forms-sample" onSubmit={process_airtel}  >

                                                                                    <div className="email-compose-fields" data-select2-id={14}>










                                                                                        <div className="description mt-3">
                                                                                            <div className="form-group row py-0">
                                                                                                <label className="col-md-3 control-label">Mobile</label>
                                                                                                <div className="col-md-9">




                                                                                                    <NumberFormat
                                                                                                        required={"required"}
                                                                                                        className={" form-control "}
                                                                                                        placeholder="Your Phone number e.g 0703956277"
                                                                                                        value={Airteldata.phone}
                                                                                                        onChange={(e)=>setAirtelData({...Airteldata,phone:e.target.value})}

                                                                                                        format="#### ### ###" />



                                                                                                </div>
                                                                                            </div>
                                                                                        </div>







                                                                                        <div className="description mt-3">
                                                                                            <div className="form-group row py-0">
                                                                                                <label className="col-md-3 control-label">Secret Code</label>
                                                                                                <div className="col-md-9">




                                                                                                    <NumberFormat
                                                                                                        required={"required"}
                                                                                                        className={" form-control "}
                                                                                                        placeholder="Enter secret Code"
                                                                                                        value={Airteldata.secret_code}
                                                                                                        onChange={(e)=>setAirtelData({...Airteldata,secret_code:e.target.value})}
                                                                                                        format="### ###"
                                                                                                    />




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>





                                                                                    </div>


                                                                                    <div className="form-group row pr-2 pl-2">

                                                                                        <div className="col-md-2">
                                                                                        </div>

                                                                                        <div className="col-md-10">
                                                                                            <div className="form-group mb-0">






                                                                                                {

                                                                                                    !sending_airtel ?
                                                                                                        <button type={"submit"} className="btn btn-success  btn-space btn-block">Send</button>

                                                                                                        :
                                                                                                        <button type={"button"} disabled={true} className="btn  btn-success btn-space btn-block">   Requesting....</button>

                                                                                                }



                                                                                            </div>
                                                                                        </div>


                                                                                    </div>

                                                                                </form>


                                                                            </div>
                                                                        </div>
                                                                    </div>









                                                                </div>
                                                            </div>





                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {/* middle wrapper end */}
                                        {/* right wrapper start */}
                                        <div className="d-none d-xl-block col-xl-3 right-wrapper">

                                        </div>
                                        {/* right wrapper end */}
                                    </div>
                                </div>






                </div>
                </div>
            </div>
            }



        </div>
    )
}