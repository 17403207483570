import React, {useEffect, useState} from "react";
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import {Link, useLocation} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
var urlencode = require('urlencode');
export default  ()=>{

    const [Search,setSearch] = useState([])
    const [Data,setData] = useState([])
    const [page,setpage] = useState(0)


    let value = true;
    let isBottomValue = true;

    let reload = true;



    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();




    const submitAlert=(id) => confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                    <h1>Are you sure?</h1>
                    <p>Do you want to delete this Contact?</p>

                    <div className="modal-footer">
                        <button className={'btn btn-primary btn-sm'} onClick={onClose}>Ignore</button>
                        <button
                            className={'btn btn-success btn-sm'}
                            onClick={() => {

                                setData(results =>results.filter(data => data.id !== id));
                                confirm_delete(id);
                                onClose();



                            }}
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            );
        }
    });




    const handleDelete=(id)=>{

        // setnew_data(newData);

        submitAlert(id)

        console.log(id);
    }





    const confirm_delete=(id)=>{


        fetch(utils.base_url + 'api/delete_contact_list_item/'+id+'/'+ReactSession.get('id'),
            {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(json => {


                reload = false;
              //  load_data(page)


            })
            .catch((e) => {

                console.log(e);

            })


    }



    const load_data=(list_id)=>{


        {

            isBottomValue = false;
            {


                fetch(utils.base_url + 'api/list_contact_details/'+ReactSession.get('id')+"/"+list_id,
                    {
                        method: 'POST',
                        body: JSON.stringify({ Search }),
                        headers: {'Content-Type': 'application/json'},


                    }).then(res => res.json())
                    .then(json => {




                        if(json.length > 0)
                        {
                            setData(json);
                        }else{
                            setData([]);
                        }




                        if(json.length > 0 && reload)
                        {

                            isBottomValue = true;
                            value = false;



                            let newData = Data

                            json.map(result=> {

                                newData = [...newData, result]

                            })


                            // setData(newData);







                        }

                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }


    const scroll={

        overflowY: 'scroll',

        width:'100%',

        height:'800px',



    };
    const FontSize={


        fontSize:12

    };



    const handleScroll=(event)=>{


        const target = event.target;

        console.log(target);

        if(target.scrollHeight - target.scrollTop == target.clientHeight)
        {

            setpage(page+1);


        }




    }

    ReactSession.set("page",0);

    useEffect(()=>{



        let list_id = JSON.parse(query.get('list_id'));


        load_data(list_id)


    },[Search]);



    const update_list=(id,data)=>{


        console.log(id);
        console.log("data",data);

        fetch(utils.base_url + 'api/update_list_item/'+id+'/'+ReactSession.get('id'),
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(json => {






            })
            .catch((e) => {

                console.log(e);

            })


    }




    const handleItemVisibililty=(data,e, index)=> {


        console.log(e.target)
        console.log("index",index)
        const name  = e.target.dataset.name;
        const list = [...Data];

        console.log(JSON.stringify(list));
        list[index][name+"_visible"] =  list[index][name+"_visible"]?false:true;
        setData(list);


        !list[index][name+"_visible"] && update_list( list[index]["id"],data)

        console.log(e.target.dataset.name);


    }




    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...Data];

        console.log(JSON.stringify(list));
        list[index][name] = value;
        setData(list);



    };







    return (
      <div>
          <div className="col-lg-12 stretch-card">
              <div className="col-lg-12">






                  <div className="email-head">
                      <div className="mt-3">

                          <h4>{(query.get('list_name'))} ({Data.length}) contacts</h4>



                      </div>
                  </div>






                  <div >




                      <div className="form-group mt-3">
                          {/*<label htmlFor="exampleInputEmail1">First name</label>*/}
                          <input name={"create-firstname"} type="text"  required={"required"}  onChange={

                              (e)=> setSearch({...Search,search:e.target.value})

                          } className="form-control" placeholder="Search contact name" />
                      </div>



                      <div className="table-responsive mt-3" onScroll={handleScroll} style={scroll}>
                          <table className="table table-hover mb-0">
                              <thead>
                              <tr>

                                  <th className="pt-0">DateTime</th>
                                  <th className="pt-0">Name</th>
                                  <th className="pt-0">Mobile</th>




                                  <th className="pt-0"></th>
                              </tr>
                              </thead>
                              <tbody>

                              {
                                  Data && Data.map((data,i)=>(




                                      <tr >



                                          <td>{utils.getTimestring(data.created_at)}</td>


                                          <td>



                                              {
                                                  ! data.name_visible &&


                                                  <span>
                                                      {data.name}
                                                  </span>
                                              }






                                              {
                                                  data.name_visible &&

                                                  <div className="col-md-12">
                                                      <div className="mb-2">
                                                          <input type="text"

                                                                 name={'name'}
                                                                 onChange={(e) => handleInputChange(e, i)}

                                                                 value={data.name}
                                                                 className="form-control col-md-12" placeholder="List name" required/>
                                                      </div>
                                                  </div>
                                              }






                                              <button className={"btn btn-sm "}    data-id={i}  onClick={(e)=>handleItemVisibililty(data,e, i)}>
                                                  <i
                                                      data-id={i}
                                                      data-name={"name"}

                                                      className={"mdi mdi-pencil mdi-18px"}></i>
                                              </button>







                                              </td>

                                          <td>



                                              {
                                                  ! data.mobile_visible &&


                                                  <span>
                                                      {data.mobile}
                                                  </span>
                                              }






                                              {
                                                  data.mobile_visible &&

                                                  <div className="col-md-12">
                                                      <div className="mb-2">
                                                          <input type="text"

                                                                 name={'mobile'}
                                                                 onChange={(e) => handleInputChange(e, i)}

                                                                 value={data.mobile}
                                                                 className="form-control col-md-12" placeholder="mobile" required/>
                                                      </div>
                                                  </div>
                                              }






                                              <button className={"btn btn-sm "}    data-id={i}  onClick={(e)=>handleItemVisibililty(data,e, i)}>
                                                  <i
                                                      data-id={i}
                                                      data-name={"mobile"}

                                                      className={"mdi mdi-pencil mdi-18px"}></i>
                                              </button>







                                              </td>





                                          <td>

                                              <i className={"mdi mdi-delete mdi-18px m-2 text-danger"} style={{cursor:'pointer'}} onClick={()=>handleDelete(data.id)}></i>



                                          </td>

                                      </tr>




                                  ))
                              }

                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>


      </div>
    );
}