import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router";
import utils from "../../Utils";
import Swal from "sweetalert2";
import mtn from "../../../assets/images/icons/mtn.png";
import NumberFormat from "react-number-format";
import airtel from "../../../assets/images/icons/airtel-logo.png";
import card from "../../../assets/images/icons/visa-mastercard.png";
import React from "react";
import {Link} from "react-router-dom";
import {ReactSession} from "react-client-session";
import Select from 'react-select'
export default  ()=> {

    const [routes,setRoutes] = useState([]);
    const [Data,setData] = useState({});

    const [Premium,setPremium] = useState([]);
    const [PremiumNetworks,setPremiumNetworks] = useState([]);


    const load_routes=()=>{


        {

            {


                fetch(utils.base_url + 'api/routes/',
                    {
                        method: 'POST',

                        headers: {'Content-Type': 'application/json'},

                    }).then(res => res.json())
                    .then(json => {




                        let newData = []

                        json.map(result=> {

                            newData = [...newData, {
                                value: result.id, label: result.routeName
                            }]

                        })


                        setRoutes(newData);



                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }




    const getValue = (e) => {
        const { name, value } = e.target;


         //const list = [...Networks];
         const list = [...user_per_networks];

         console.log(value)

        // list[index][name] = value;
        //
        //
        //     return list[index][name];




    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;


         const list = [...Networks];
       //  const list = [...user_per_networks];

        // console.log(JSON.stringify(list));


        console.log(Networks);




      //  setUser_per_networks({...user_per_networks,name:e.target.value})

        // if(list[index])
        // {
             list[index][name] = value;
         //    setUser_per_networks(list);
             setNetworks(list);
        // }else{
        //     const list = [...Bufferuser_per_networks];
        //     list[index][name] = value;
        //     setUser_per_networks(list);
        // }




    };

    const handlePremiumChange = (e, index, type) => {




        if(type === "network")
        {
            // const list = [...PremiumNetworks];
            const { name, value } = e;


            let counter = 0;


            let NewNetworks = [...PremiumNetworks];


            NewNetworks.map((results,i)=>{


             // console.log(counter)


              if(results.networkid === index)
              {
                  console.log("Before",NewNetworks, value);
                  NewNetworks[i].routeid = value
                  console.log("After",NewNetworks);
              }



              counter++;


            })


            setPremiumNetworks(NewNetworks)




            //
            //
            // const bufferItem =  PremiumNetworks.filter(person =>
            // {
            //     return person.networkid  === index
            // });
            //
            //
            //
            //
            // const currentNetworks =  PremiumNetworks.filter(person =>
            // {
            //     return person.networkid  !== index
            // });
            //
            //
            // console.log(bufferItem);
            // console.log(currentNetworks);
            //
            //
            // let list = [...currentNetworks];
            //
            //
            // bufferItem.map(bufferItem=>{
            //
            //     list = [...currentNetworks, {
            //         id:bufferItem.id,
            //         networkid:bufferItem.networkid,
            //         routeid:value,
            //         amount:bufferItem.amount,
            //         prefix:bufferItem.prefix
            //     }];
            //
            // })



           // list.sort();

             // setPremiumNetworks(list)


        }else

            {

                const { name, value } = e.target;



                // let NewNetworks = PremiumNetworks;


                let NewNetworks = [...PremiumNetworks];
                let counter = 0;

                NewNetworks.map(results=>{


                    console.log(counter)


                    if(results.networkid === index)
                    {

                        NewNetworks[counter].amount = value

                    }

                    counter++;


                })


                setPremiumNetworks(NewNetworks);



                //
                // const bufferItem =  PremiumNetworks.filter(person =>
                // {
                //     return person.networkid  === index
                // });
                //
                //
                //
                //
                // const currentNetworks =  PremiumNetworks.filter(person =>
                // {
                //     return person.networkid  !== index
                // });
                //
                //
                // console.log(bufferItem);
                // console.log(currentNetworks);
                //
                //
                // let list = [...currentNetworks];
                //
                //
                // bufferItem.map(bufferItem=>{
                //
                //     list = [...currentNetworks, {
                //         id:bufferItem.id,
                //         networkid:bufferItem.networkid,
                //         routeid:bufferItem.routeid,
                //         amount:value,
                //         prefix:bufferItem.prefix
                //     }];
                //
                // })

              //  list.sort();

               // setPremiumNetworks(list)

        }





    };


    const [Per_networks, setPer_networks]= useState([{}]);
    const [Bufferuser_per_networks, setBufferUser_per_networks]= useState([{}]);
    const [user_per_networks, setUser_per_networks]= useState([{}]);
    const [items, setItems]= useState([{}]);
    const [CardUrl,setCardUrl] = useState(null);
    const [CardResponse,setCardResponse] = useState(null);
    const [AirtelResponse,setAirtelResponse] = useState(null);
    const [mtnResponse,setmtnResponse] = useState(null);
    const [mtndata,setMtnData] = useState({});
    const [Airteldata,setAirtelData] = useState({});
    const [Carddata,setCardData] = useState({});
    const [sending,setSending] = useState(false);
    const [sending_card,setsending_card] = useState(false);
    const [sending_airtel,setsending_airtel] = useState(false);
    const [sending_mtn,setsending_mtn] = useState(false);
    const [error,setError] = useState([]);

    const [cardPanel,setCardPanel] = useState(-1);
    const [airtelPanel,setAirtelPanel] = useState(-1);
    const [mtnPanel,setMtnPanel] = useState(-1);
    const [userData,setUserData] = useState({});
    const [isValid,sIsValid] = useState(false);
    const {page} = useParams();



    const [Networks,setNetworks] = useState([])


    const loadNetworks=(userid)=>{


        {

            {


                fetch(utils.base_url + 'api/networks/',
                    {
                        method: 'POST',

                        headers: {'Content-Type': 'application/json'},

                    }).then(res => res.json())
                    .then(json => {







                        if(json.length > 0)
                        {



                            let newData = Networks
                            let BufferData = [];

                            let default_settings = {};

                            json.map(result=> {

                                newData = [...newData, result]


                                let value = [];


                                value[result.id] = {smscharge:0};


                                // BufferData = [...BufferData,value]
                                BufferData = [...BufferData,{
                                    id:result.id,
                                    prefix:result.prefix,
                                    networkid:result.id,
                                    routeid:0,
                                    amount:0
                                }]

                            })


                             setPremiumNetworks(BufferData);
                            setPremium(BufferData);
                            setNetworks(newData);
                            setBufferUser_per_networks(BufferData);
                            setUser_per_networks(BufferData);


                            loaduser_per_networks(userid,newData );
                            loadpremium_user(userid,newData );



                        }

                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }

    const loaduser_per_networks=(userid, NetworkData)=>{


        {

            {


                fetch(utils.base_url + 'user/user_per_networks/'+userid,
                    {
                        method: 'POST',

                        headers: {'Content-Type': 'application/json'},

                    }).then(res => res.json())
                    .then(json => {










                        if(json.length)
                        {



                            console.log(json)

                            //    setData(results =>results.filter(data => data.id !== id));

                            let NewNetworks = []
                            let newData = NetworkData

                            json.map(result=> {


                                console.log(result);


                                newData.filter(data=> data.id === result.networkid).map(resp=>{




                                    NewNetworks = [...NewNetworks, {

                                        id:resp.id,
                                        network_name:resp.network_name,
                                        prefix:resp.prefix,
                                        amount:result.amount,

                                    }]




                                    console.log('response',resp);

                                });


                               // newData = [...newData, result]




                            })


                            setNetworks(NewNetworks);
                            setUser_per_networks(json);
                            // setItems(newData);






                        }

                        else{
                        //   alert(JSON.stringify(Networks));
                           // setUser_per_networks(Networks)

                        }

                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }


    const loadpremium_user=(userid, NetworkData)=>{


        {

            {


                fetch(utils.base_url + 'user/premium_user/'+userid,
                    {
                        method: 'POST',

                        headers: {'Content-Type': 'application/json'},

                    }).then(res => res.json())
                    .then(json => {










                        if(json.length)
                        {



                            console.log(json)

                            //    setData(results =>results.filter(data => data.id !== id));

                            let NewNetworks = []
                             let newData = NetworkData
                            //let newData = []

                            json.map(result=> {


                                console.log(result);


                                newData.filter(data=> data.id === result.networkid).map(resp=>{




                                    NewNetworks = [...NewNetworks, {

                                        id:resp.id,
                                        network_name:resp.network_name,
                                        prefix:resp.prefix,
                                        amount:result.amount,
                                        routeid:result.routeid,
                                        networkid:result.networkid,

                                    }]




                                    console.log('response',resp);

                                });


                               // newData = [...newData, result]




                            })

                           /// NewNetworks.sort();
                            setPremiumNetworks(NewNetworks)

//                            setPremiumNetworks(NewNetworks);
                          //  setNetworks(NewNetworks);
                            //setUser_per_networks(json);
                            // setItems(newData);






                        }

                        else{
                        //   alert(JSON.stringify(Networks));
                           // setUser_per_networks(Networks)

                        }

                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }



    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [data,setdata] = useState([]);
    const process_per_sms = e => {
        e.preventDefault()


        // console.log(JSON.stringify({ mtndata }));

        // setmtnResponse('Your request has been submitted. Please wait for a mobile money PIN request to proceed or dial *165# to approve this payment');

        setsending_mtn(true);


        fetch(utils.base_url+'user/set_costpersms/'+userData.id, {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ userData }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setsending_mtn(false);
                // setmtnResponse('Your request is in progress. Dial *165# to approve this transaction or try again');
                setmtnResponse(json.ResultMessage);


                if(parseInt(json.ResultCode) === 10001)
                {


                    // Swal.fire(
                    //     {
                    //         title: json.ResultMessage,
                    //         // text: json.Msisdn,
                    //         icon: 'success',
                    //         showCancelButton: false,
                    //         showConfirmButton:false,
                    //         // confirmButtonColor: '#3085d6',
                    //         // cancelButtonColor: '#d33',
                    //         // confirmButtonText: 'Proceed',
                    //         // cancelButtonText: 'Proceed',
                    //         // allowOutsideClick: false,
                    //
                    //
                    //     })


                    setError({
                        icon:'bell',
                        header:'Thank you',
                        class_Name:'success',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });

                    e.target.reset();


                    setMtnData({})

                }else{


                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })

                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                    e.target.reset();


                    setMtnData({})
                }



                //setUser(json.user)

            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    const process_per_network = e => {
        e.preventDefault()


        // console.log(JSON.stringify({ Airteldata }));

        // setAirtelResponse('Your request has been submitted');

        setsending_airtel(true);


        fetch(utils.base_url+'user/process_per_network/'+userData.id+"/"+ReactSession.get('id'), {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ Networks }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setsending_airtel(false);
                // setmtnResponse('Your request is in progress. Dial *165# to approve this transaction or try again');
                setAirtelResponse(json.ResultMessage);


                if(parseInt(json.ResultCode) === 10001)
                {



                    // Swal.fire(
                    //     {
                    //         title: json.ResultMessage,
                    //         // text: json.Msisdn,
                    //         icon: 'success',
                    //         showCancelButton: false,
                    //         showConfirmButton:false,
                    //         // confirmButtonColor: '#3085d6',
                    //         // cancelButtonColor: '#d33',
                    //         // confirmButtonText: 'Proceed',
                    //         // cancelButtonText: 'Proceed',
                    //         // allowOutsideClick: false,
                    //
                    //
                    //     })


                    //
                    // setError({
                    //     icon:'bell',
                    //     header:'Thank you',
                    //     class_Name:'success',
                    //     status_login:true,
                    //     status:true,
                    //     message:json.ResultMessage,
                    // });
                    //
                    // e.target.reset();
                    //
                    //
                    // setAirtelData({})

                }else{




                    // Swal.fire(
                    //     {
                    //         title: json.ResultMessage,
                    //         // text: json.Msisdn,
                    //         icon: 'info',
                    //         showCancelButton: false,
                    //         showConfirmButton:false,
                    //         // confirmButtonColor: '#3085d6',
                    //         // cancelButtonColor: '#d33',
                    //         // confirmButtonText: 'Proceed',
                    //         // cancelButtonText: 'Proceed',
                    //         // allowOutsideClick: false,
                    //
                    //
                    //     })


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                    e.target.reset();


                    setAirtelData({})
                }



                //setUser(json.user)

            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };

    const process_premium = e => {
        e.preventDefault()






        setsending_card(true);


        fetch(utils.base_url+'user/set_premium/'+userData.id+"/"+ReactSession.get('id'), {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ PremiumNetworks }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setsending_card(false);
                // setmtnResponse('Your request is in progress. Dial *165# to approve this transaction or try again');
                setCardResponse(json.ResultMessage);




                if(parseInt(json.ResultCode) === 10001)
                {




                  //  setCardUrl(json.Data.link);

                    //
                    // e.target.reset();
                    //
                    //
                    // setCardData({})

                }else{




                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                    e.target.reset();


                    setAirtelData({})
                }



                //setUser(json.user)

            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    const load_user_info = (page)=>{




        fetch(utils.base_url + 'user/user_details/'+page,
            {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(data => {


               let  json = data[0]




                if(json.id)
                {

                    setUserData(json);

                    sIsValid(true);


                    // alert(json.tarrif_plan)
                    
                    switch (json.tarrif_plan) {

                        case "per_sms":
                            setMtnPanel(parseInt(mtnPanel)*-1);
                            setAirtelPanel(-1);
                            setCardPanel(-1);
                            break;

                        case "per_network":
                            setAirtelPanel(parseInt(airtelPanel)*-1);
                            setMtnPanel(-1);
                            setCardPanel(-1);
                            break;
                        case "premium":
                            setCardPanel(parseInt(cardPanel)*-1);
                            setMtnPanel(-1);
                            setAirtelPanel(-1);
                            break;

                    }

                    
                    
                }else{

                    setUserData({})
                    sIsValid(false);

                }







            })
            .catch((e) => {

                console.log(e);

            })










    }
    console.log(page);
    useEffect(()=>{





        let data = JSON.parse(query.get('details'));

        load_user_info(data.id);

        setdata(data);


        load_routes();

        loadNetworks(data.id);

    },[]);


    const getNetworkCharge=(id)=>
    {


        user_per_networks.forEach(data=>{

           // console.log(data);

            if(data.networkid === id)
            {

              //  alert('seen')
                return data.amount;

            }else{

                return 0;

            }

        })


    }



    return (  <div className="row profile-body">


        <div className="col-md-8 middle-wrapper border-sm-right">
                                <div className="row">
                                    <div className="col-md-12 grid-margin">
                                        <div className=" rounded">

                                            <div className="card-body">


                                                {
                                                    mtnResponse && <div className="alert alert-success text-center" role="alert">
                                                        {mtnResponse}
                                                    </div>
                                                }

                                                <div >
                                                    <div id="accordion" className="accordion" role="tablist">



                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="headingOne">
                                                                <h6 className="mb-0">
                                                                                <span data-toggle="collapse" style={{cursor:'pointer'}} onClick={()=>{

                                                                                    setMtnPanel(parseInt(mtnPanel)*-1);
                                                                                    setAirtelPanel(-1);
                                                                                    setCardPanel(-1);

                                                                                }
                                                                                } aria-expanded="true" aria-controls="collapseOne" className>


                                                                                 Charge  Per SMS
                                                                                </span>
                                                                </h6>
                                                            </div>
                                                            <div id="collapseOne" className={mtnPanel > 0?"collapse show":"collapse"}  role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" style={{}}>
                                                                <div className="card-body">




                                                                    <form className="forms-sample" onSubmit={process_per_sms} >

                                                                        <div className="email-compose-fields" data-select2-id={14}>






                                                                            <div className="description mt-2">
                                                                                <div className="form-group row py-0">
                                                                                    <label className="col-md-2 control-label">Amount</label>
                                                                                    <div className="col-md-10">




                                                                                        <NumberFormat
                                                                                            required={"required"}
                                                                                            className={" form-control "}
                                                                                            placeholder="Enter charge per sms"
                                                                                            value={userData.costpersms}
                                                                                            onChange={(e)=>setUserData({...userData,costpersms:e.target.value})}
                                                                                            thousandSeparator={','}
                                                                                            suffix={'/-'}
                                                                                        />




                                                                                    </div>
                                                                                </div>
                                                                            </div>







                                                                        </div>


                                                                        <div className="form-group row pr-2 pl-2">

                                                                            <div className="col-md-2">
                                                                            </div>

                                                                            <div className="col-md-10">
                                                                                <div className="form-group mb-0">






                                                                                    {

                                                                                        !sending_mtn ?
                                                                                            <button type={"submit"} className="btn btn-success  btn-space btn-block">Save tarrif</button>

                                                                                            :
                                                                                            <button type={"button"} disabled={true} className="btn  btn-success btn-space btn-block">   Authorizing....</button>

                                                                                    }



                                                                                </div>
                                                                            </div>


                                                                        </div>

                                                                    </form>


                                                                </div>
                                                            </div>
                                                        </div>

                                                        {
                                                            AirtelResponse && <div className="alert alert-success text-center" role="alert">
                                                                {AirtelResponse}
                                                            </div>
                                                        }


                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="headingOne">
                                                                <h6 className="mb-0">
                                                                                <span data-toggle="collapse" style={{cursor:'pointer'}} onClick={()=>{

                                                                                    setAirtelPanel(parseInt(airtelPanel)*-1);
                                                                                    setMtnPanel(-1);
                                                                                    setCardPanel(-1);
                                                                                }
                                                                                } aria-expanded="true" aria-controls="collapseOne" className>


                                                                           Charge Per Mobile Network
                                                                                </span>
                                                                </h6>
                                                            </div>
                                                            <div id="collapseOne" className={airtelPanel > 0?"collapse show":"collapse"}  role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" style={{}}>
                                                                <div className="card-body">







                                                                    <form className="forms-sample" onSubmit={process_per_network}  >

                                                                        <div className="email-compose-fields" data-select2-id={14}>








                                                                            {
                                                                                Networks && Networks.map((data,i)=>(







                                                                                    <div className="description mt-1">
                                                                                        <div className="form-group row py-0">
                                                                                            <label className="col-md-6 control-label">{data.network_name} ({data.prefix})</label>
                                                                                            <div className="col-md-6">




                                                                                                <NumberFormat
                                                                                                    // name={data.id}
                                                                                                    name={"amount"}
                                                                                                    required={"required"}
                                                                                                    className={" form-control "}
                                                                                                    placeholder="SMS cost"
                                                                                                      value={data.amount}
                                                                                                    // value={getNetworkCharge(data.id)}
                                                                                                    // value={(e)=>getValue(e)}
                                                                                                    onChange={(e) => handleInputChange(e, i)}
                                                                                                    // onChange={(e)=>setUserData({...userData.data.id,value:e.target.value})}
                                                                                                    thousandSeparator={','}
                                                                                                    suffix={'/-'}
                                                                                                />





                                                                                            </div>
                                                                                        </div>
                                                                                    </div>






                                                                                ))
                                                                            }















                                                                        </div>


                                                                        <div className="form-group row pr-2 pl-2">

                                                                            <div className="col-md-6">
                                                                            </div>

                                                                            <div className="col-md-6">
                                                                                <div className="form-group mb-0">






                                                                                    {

                                                                                        !sending_airtel ?
                                                                                            <button type={"submit"} className="btn btn-success  btn-space btn-block">Send</button>

                                                                                            :
                                                                                            <button type={"button"} disabled={true} className="btn  btn-success btn-space btn-block">   Requesting....</button>

                                                                                    }



                                                                                </div>
                                                                            </div>


                                                                        </div>

                                                                    </form>


                                                                </div>
                                                            </div>
                                                        </div>






                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="headingOne">
                                                                <h6 className="mb-0">
                                                                                <span data-toggle="collapse" style={{cursor:'pointer'}} onClick={()=>{
                                                                                    setCardPanel(parseInt(cardPanel)*-1);

                                                                                    setAirtelPanel(-1);
                                                                                    setMtnPanel(-1);

                                                                                }
                                                                                } aria-expanded="true" aria-controls="collapseOne" className>


                                                                           Charge Per Mobile Network per Route
                                                                                </span>
                                                                </h6>
                                                            </div>
                                                            <div id="collapseOne" className={cardPanel > 0?"collapse show":"collapse"}  role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" style={{}}>
                                                                <div className="card-body">







                                                                    <form className="forms-sample" onSubmit={process_premium}  >

                                                                        <div className="email-compose-fields" data-select2-id={14}>








                                                                            {
                                                                                PremiumNetworks && PremiumNetworks.map((data,i)=>(







                                                                                    <div className="description mt-1">
                                                                                        <div className="form-group row py-0">
                                                                                            <label className="col-md-2 control-label">{data.prefix}</label>


                                                                                            <div className="col-md-6">



                                                                                                            <Select

                                                                                                                value={routes.filter(option => option.value === data.routeid)}


                                                                                                                options={routes}
                                                                                                                    onChange={(e)=>
                                                                                                                    {

                                                                                                                        console.log(e)
                                                                                                                        // setPremiumNetworks({...data,default_routeid:e.value})
                                                                                                                         handlePremiumChange(e, data.networkid,'network')

                                                                                                                    }
                                                                                                                    } />




                                                                                            </div>
                                                                                            <div className="col-md-4">




                                                                                                <NumberFormat
                                                                                                    // name={data.id}
                                                                                                    name={"amount"}
                                                                                                    required={"required"}
                                                                                                    className={" form-control "}
                                                                                                    placeholder="SMS cost"
                                                                                                    value={data.amount}
                                                                                                    // value={getNetworkCharge(data.id)}
                                                                                                    // value={(e)=>getValue(e)}
                                                                                                    onChange={(e) => handlePremiumChange(e, data.networkid,"amount")}
                                                                                                    // onChange={(e)=>setUserData({...userData.data.id,value:e.target.value})}
                                                                                                    thousandSeparator={','}
                                                                                                    suffix={'/-'}
                                                                                                />





                                                                                            </div>
                                                                                        </div>
                                                                                    </div>






                                                                                ))
                                                                            }















                                                                        </div>


                                                                        <div className="form-group row pr-2 pl-2">

                                                                            <div className="col-md-8">
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <div className="form-group mb-0">






                                                                                    {

                                                                                        !sending_card ?
                                                                                            <button type={"submit"} className="btn btn-success  btn-space btn-block">Send</button>

                                                                                            :
                                                                                            <button type={"button"} disabled={true} className="btn  btn-success btn-space btn-block">   Requesting....</button>

                                                                                    }



                                                                                </div>
                                                                            </div>


                                                                        </div>

                                                                    </form>


                                                                </div>
                                                            </div>
                                                        </div>





                                                    </div>
                                                </div>





                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>


        <div className="d-none d-md-block col-md-4  left-wrapper">
            <div className=" rounded">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <h6 className="card-title mb-0">First Name : </h6>

                    </div>
                    <p>{data.firstname}</p>

                    <div className="mt-3">
                        <label className="tx-11 font-weight-bold mb-0 text-uppercase">Last Name:</label>
                        <p className="text-muted">{data.lastname}</p>
                    </div>
                    <div className="mt-3">
                        <label className="tx-11 font-weight-bold mb-0 text-uppercase">Email:</label>
                        <p className="text-muted">{data.email}</p>
                    </div>


                    {/*<div className="mt-3">*/}
                    {/*<label className="tx-11 font-weight-bold mb-0 text-uppercase">Account Balance:</label>*/}
                    {/*<p className="text-muted">{parseFloat(data.account_balance).toLocaleString()}</p>*/}
                    {/*</div>*/}


                </div>
            </div>
        </div>





    </div>)

}