import {Link} from "react-router-dom";
import React from "react";
import {useParams} from "react-router";
import NewRoute from "./NewRoute";
import ListRoutes from "./ListRoutes";
import { parse } from 'query-string';
import EditRoute from "./EditRoute";

export default ({change_state, change_sublink})=> {

    change_state('messaging_routes');
    change_sublink('list_routes');


    const {action} = useParams();








    const getTitle=(link)=>{

        switch (link) {

            case "new_route":
                return 'Add new messaging route';

            case "list_routes":
                return 'List messaging routes';


            case "edit_details":
                return 'Edit messaging route details';


        }


    }


    const switching =()=>{

        switch (action) {



            case "edit_details":

                return <EditRoute />;


            case "new_route":
                return <NewRoute/>;



            case "list_routes":
                return <ListRoutes/>;



        }
    }



    return (
        <div className="inbox-wrapper">
    <div >
        <div className="card">
            <div className="card-body">



                <div className="email-head">
                    <div className="email-head-title d-flex align-items-center">
                        Messaging routes
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="col-lg-3 email-aside border-sm-right">
                        <div className="aside-content">


                            <div className="aside-nav ">
                                <ul className="nav">



                                    <li >

                                        <Link to={"/messaging_routes/list_routes"} className={"nav-link"} >



                                            <button
                                                className={action === 'list_routes'?' btn btn-primary btn-block':' btn btn-outline-primary btn-block'}

                                            >List messaging routes</button>




                                        </Link>

                                    </li>



                                    <li >

                                        <Link to={"/messaging_routes/new_route"} className={"nav-link"} >



                                            <button
                                                className={action === 'new_route'?' btn btn-primary btn-block':' btn btn-outline-primary btn-block'}

                                            >Add Messaging Route</button>




                                        </Link>

                                    </li>











                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 email-content">
                        <div className="email-inbox-header">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="email-title mb-2 mb-md-0">
                                        {getTitle(action)}  </div>
                                </div>

                            </div>
                        </div>

                        <div className="email-list">

                            {
                                switching()
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
)
}