import utils from "./../../Utils";
import React, {useEffect, useState} from "react";
import {ReactSession} from "react-client-session";
import {Link} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
var urlencode = require('urlencode');
export default ()=>{

    const [Data,setData] = useState([])


    const load_data=()=>{


        {

            {


                fetch(utils.base_url + 'api/networks/',
                    {
                        method: 'POST',

                        headers: {'Content-Type': 'application/json'},

                    }).then(res => res.json())
                    .then(json => {







                        if(json.length > 0)
                        {



                            let newData = Data

                            json.map(result=> {

                                newData = [...newData, result]

                            })


                            setData(newData);






                        }

                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }


    useEffect(()=>{

        load_data();
    },[]);



    const confirm_delete=(id)=>{


        fetch(utils.base_url + 'api/delete_network/'+id+'/'+ReactSession.get('id'),
            {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(json => {





            })
            .catch((e) => {

                console.log(e);

            })


    }



    const submitAlert=(id) => confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                    <h1>Are you sure?</h1>
                    <p>Do you want to delete this Network?</p>

                    <div className="modal-footer">
                        <button className={'btn btn-primary btn-sm'} onClick={onClose}>Ignore</button>
                        <button
                            className={'btn btn-success btn-sm'}
                            onClick={() => {

                                setData(results =>results.filter(data => data.id !== id));
                                confirm_delete(id);
                                onClose();



                            }}
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            );
        }
    });



    const handleDelete=(id)=>{


        submitAlert(id)

        console.log(id);
    }


    return (<div>

        <div className="table-responsive mt-3" >
            <table className="table table-hover mb-0">
                <thead>
                <tr>
                    <th className="pt-0">DateTime</th>
                    <th className="pt-0">By</th>
                    <th className="pt-0">Network</th>
                    <th className="pt-0">Prefix</th>


                    <th className="pt-0">Route</th>
                    <th className="pt-0"> Cost</th>
                    <th className="pt-0"></th>
                    <th className="pt-0"></th>

                </tr>
                </thead>
                <tbody>


                {
                    Data && Data.map(data=>(




                        <tr>


                            {/*<td>{data.id}</td>*/}
                            <td>{utils.getTimestring(data.created_at)}</td>
                            <td>{data.user.firstname} {data.user.lastname}</td>
                            <td>{data.network_name}</td>
                            <td>{data.prefix}</td>
                            <td>{data.route.routeName}</td>
                            <td>{data.default_smscost}</td>

                            <td >
                                <i className={"mdi mdi-delete mdi-18px text-danger" } style={{cursor:'pointer'}} onClick={()=>handleDelete(data.id)}></i>
                            </td>

                            <td>
                                <Link to={"/mobile_networks/edit_details/?details="+urlencode(JSON.stringify(data))}>
                                    <i className={"mdi mdi-pencil mdi-18px text-primary"} style={{cursor:'pointer'}}></i>
                                </Link></td>

                        </tr>




                    ))
                }





                </tbody>
            </table>
        </div>

    </div>);

}