import React, {useEffect, useState} from "react";
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import {Link} from "react-router-dom";
import urlencode from "urlencode";

export default  ({change_state})=>{
      change_state('transaction_history');
    const [Data,setData] = useState([])
    const [page,setpage] = useState(0)


    let value = true;
    let isBottomValue = true;

    let reload = true;


    const load_data=(_page)=>{


        {

            isBottomValue = false;
            {


                fetch(utils.base_url + 'api/list_transactions_admin/'+ReactSession.get('id')+"/"+_page,
                    {
                        method: 'POST',

                        headers: {'Content-Type': 'application/json'},

                    }).then(res => res.json())
                    .then(json => {







                        if(json.length > 0 && reload)
                        {

                            isBottomValue = true;
                            value = false;



                            let newData = Data

                            json.map(result=> {

                                newData = [...newData, result]

                            })


                            setData(newData);






                        }

                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }



    const scroll={

        overflowY: 'scroll',

        width:'100%',

        height:'800px',


    };



    const handleScroll=(event)=>{


        const target = event.target;

        console.log(target);

        if(target.scrollHeight - target.scrollTop == target.clientHeight)
        {

            setpage(page+1);


        }




    }

    ReactSession.set("page",0);

    useEffect(()=>{


        load_data(page)


    },[page]);


    const getTxstatus=(status)=>{

//        alert(status);

        switch (status) {

            case 0:
               return (<i className={"mdi mdi-clock text-warning"}>Pending</i>)
                break;
            case 1:
                return (<span className={"mdi mdi-check text-success"}>Success</span>)
                break;
            case 2:
                return (<span className={"mdi mdi-close text-success"}>Declined</span>)
                break;

        }

    }


    return (
      <div>
          <div className="col-lg-12 stretch-card">
              <div className="card">
                  <div className="card-body ">



                      <div className="email-head">
                          <div className="email-head-title d-flex align-items-center">
                              Transaction history
                          </div>
                      </div>




                      <div className="table-responsive mt-3" onScroll={handleScroll} style={scroll}>
                          <table className="table table-hover mb-0">
                              <thead>
                              <tr>
                                  {/*<th className="pt-0">RefId</th>*/}
                                  <th className="pt-0">DateTime</th>
                                  {/*<th className="pt-0">AccountName</th>*/}



                                  <th className="pt-0">Source</th>
                                  <th className="pt-0">Amount</th>
                                  <th className="pt-0">Status</th>
                              </tr>
                              </thead>
                              <tbody>

                              {
                                  Data && Data.map(data=>(




                                      <tr>


                                          {/*<td>{data.id}</td>*/}
                                          <td>{utils.getTimestring(data.created_at)}</td>
                                          {/*<td>*/}
                                              {/*<Link to={"/user_accounts/transaction_history/?details="+urlencode(JSON.stringify(data.user))}>*/}
                                                  {/*{data.user.firstname} {data.user.lastname}*/}
                                              {/*</Link>*/}
                                           {/*</td>*/}


                                          <td>{data.transType === 'Auto'?data.msisdn:data.transType}</td>


                                          <td>{parseFloat(data.amount).toLocaleString()}</td>


                                          <td >{getTxstatus(parseInt(data.status))}</td>
                                      </tr>




                                  ))
                              }

                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>


      </div>
    );
}