import {useEffect, useState} from "react";
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import React from "react";
import {Link} from "react-router-dom";
import urlencode from "urlencode";

export default ({change_state})=>{

    change_state('sent_messages');

    const [Data,setData] = useState([])
    const [page,setpage] = useState(0)
    const [ListData,setListData] = useState(false)

    let value = true;
    let isBottomValue = true;

    let reload = true;


    const load_data=(_page)=>{


        {

            isBottomValue = false;
            {


                fetch(utils.base_url + 'messaging/list_all_messages/'+_page,
                    {
                        method: 'POST',

                        headers: {'Content-Type': 'application/json'},

                    }).then(res => res.json())
                    .then(json => {


                        setListData(true)




                        if(json.length > 0 && reload)
                        {

                            isBottomValue = true;
                            value = false;



                            let newData = Data

                            json.map(result=> {

                                newData = [...newData, result]

                            })


                            setData(newData);






                        }

                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }


    const scroll={

        overflowY: 'scroll',

        width:'100%',

        height:'800px',


    };

    const message={



        width:'200px !important',




    };



    const handleScroll=(event)=>{


        const target = event.target;

        console.log(target);

        if(target.scrollHeight - target.scrollTop == target.clientHeight)
        {

            setpage(page+1);


        }




    }

    ReactSession.set("page",0);

    useEffect(()=>{


        load_data(page)


    },[page]);


    const getTxstatus=(status)=>{

//        alert(status);

        switch (status) {

            case 0:
                return 'email-list-item email-list-item--unread'
                break;
            case 1:
                return 'email-list-item'
                break;


        }

    }


    return (
        <div>



                <div className="row inbox-wrapper">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-lg-12 email-content">
                                        <div className="email-inbox-header">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="email-title mb-2 mb-md-0">
                                                        <span className="icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-inbox"><polyline points="22 12 16 12 14 15 10 15 8 12 2 12" /><path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" /></svg>
                                                        </span>
                                                        Sent Messages
                                                        {/*<span className="new-messages">(2 new messages)</span>*/}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">

                                                </div>
                                            </div>
                                        </div>

                                        <div className="email-list" onScroll={handleScroll} style={scroll}>





                                            {
                                                Data && Data.map(data=>(




                                                <div className={getTxstatus(parseInt(data.status))}>




                                                <Link to={"/message_details/"+data.messageout.id+"?details="+urlencode(JSON.stringify(data.messageout))+"&user="+urlencode(data.user.firstname+" "+data.user.lastname)} className="email-list-detail">
                                                    <div>
                                                        <span className="from">{data.messageout.senderid}</span>
                                                        <p className="msg"> {data.messageout.message}</p>
                                                        <p className="msg">  <span className="icon">
                          <i className={"mdi mdi-account-outline mdi-18px"}></i>
                      </span> {data.user.firstname} {data.user.lastname}</p>
                                                        <p className="msg">
                                                            <i
                                                            className="mdi mdi-arrow-bottom-right"></i> {utils.getTimestring(data.created_at)}</p>
                                                        <p className="msg"><i className="mdi mdi-alert-circle"></i> {data.counts}  ({parseFloat(data.sms_cost).toLocaleString()})UGX</p>

                                                    </div>
                                                    <span className="date">
                      <span className="icon">
                          <i className={"mdi mdi-clock mdi-16px"}></i>
                      </span>
                                                        {
                                                            utils.getTimestring(data.messageout.schedule_date)

                                                        }
                    </span>
                                                </Link>
                                            </div>



                                                ))
                                            }













                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



        </div>
    );
}