import React, {useEffect, useState} from "react";
import Alert from "../../Alert";
import NumberFormat from "react-number-format";
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";

export default ()=>{

    const [error,setError] = useState([]);
    const [data,setdata] = useState([]);
    const [Data,setData] = useState([]);
    const [sending,setSending] = useState(false);


    const scroll={

        overflowY: 'scroll',

        width:'100%',

        height:'800px',



    };


    const [page,setpage] = useState(0)

    const handleScroll=(event)=>{


        const target = event.target;

        console.log(target);

        if(target.scrollHeight - target.scrollTop == target.clientHeight)
        {

            setpage(page+1);


        }




    }


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const handleStatus=(status)=>{

        setError({...error,status_login:status});

    }


    let value = true;
    let isBottomValue = true;

    let reload = true;

    const load_data=(id,_page)=>{


        {

            isBottomValue = false;
            {


                fetch(utils.base_url + 'api/list_transactions_admin/'+id+"/"+_page,
                    {
                        method: 'POST',

                        headers: {'Content-Type': 'application/json'},

                    }).then(res => res.json())
                    .then(json => {







                        if(json.length > 0 && reload)
                        {

                            isBottomValue = true;
                            value = false;



                            let newData = Data

                            json.map(result=> {

                                newData = [...newData, result]

                            })


                            setData(newData);






                        }

                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }




    useEffect(()=>{

        let data = JSON.parse(query.get('details'));

        setdata(data);

        load_data(data.id,page)

    },[page]);


    const getStatus=(status)=>{

        switch (status) {

            case 1:
                return <i className={"mdi mdi-check mdi-24px text-success"}/>;

            case 0:
                return <i className={"mdi mdi-clock mdi-24px text-warning"}/>;
              case 2:
                return <i className={"mdi mdi-close mdi-24px text-danger"}/>;


        }



    }


    return (<div>









        <div className="row profile-body">


            <div className="col-md-8 middle-wrapper border-sm-right">


                <div className="email-list" onScroll={handleScroll} style={scroll}>


                    {
                        Data && Data.map(results=>(




                            <div className="email-list-item">

                                <div className="email-list-actions">

                                    {
                                        getStatus(results.status)
                                    }



                                </div>

                                <span className="email-list-detail">
                                    <div>
                                        <span className="from">{results.amount.toLocaleString()}</span>
                                                                                <span className="from">{results.user.firstname} {results.user.lastname}</span>

                                        <p className="msg">{results.transType === 'Auto'?results.msisdn:results.transType}</p>
                                    </div>
                                    <span className="date">
                                        {utils.getTimestring(results.created_at)}
      </span>
                                </span>
                            </div>




                        ))
                    }











                </div>

            </div>

            <div className="d-none d-md-block col-md-4  left-wrapper">
                <div className=" rounded">
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <h6 className="card-title mb-0">First Name : </h6>

                        </div>
                        <p>{data.firstname}</p>

                        <div className="mt-3">
                            <label className="tx-11 font-weight-bold mb-0 text-uppercase">Last Name:</label>
                            <p className="text-muted">{data.lastname}</p>
                        </div>
                        <div className="mt-3">
                            <label className="tx-11 font-weight-bold mb-0 text-uppercase">Email:</label>
                            <p className="text-muted">{data.email}</p>
                        </div>


                        {/*<div className="mt-3">*/}
                            {/*<label className="tx-11 font-weight-bold mb-0 text-uppercase">Account Balance:</label>*/}
                            {/*<p className="text-muted">{parseFloat(data.account_balance).toLocaleString()}</p>*/}
                        {/*</div>*/}


                    </div>
                </div>
            </div>





        </div>












    </div>)

}