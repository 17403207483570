import React,{useState,useEffect } from 'react';


const Alert=({message, icon, class_Name, header, showing, handleStatus})=>{

     const [show,setShow] = useState(true)

    useEffect(()=>{

        setShow(showing)


    },[showing]);

    console.log(show);


    let classA = "position-tl w-102 m-n1px border-t-4 brc-"+class_Name;
    let classB = "alert alert-"+class_Name+" alert-dismissible fade show mt-2";
    let Icon = "mdi mdi-"+icon+"  text-"+class_Name+" ";
    let headerClass = "text-"+class_Name+"-d1 text-130";
    let ButtonClass = "close mt-1 align-self-start ml-auto mt-n25 mr-n2 text-"+class_Name+"-d2 text-150 opacity-2 bgc-h-"+class_Name+"-l2 radius-round px-15 pt-1px pb-3px";

    console.log("class_name"+class_Name

    )


    return (




        <div >
            {
                showing &&

                <div className={classB} role="alert">
                    {/*<i className={Icon} />*/}
                  <center>{message}</center>
                    <button type="button" onClick={()=>handleStatus(false)} className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            }

        </div>


    );

}



export default Alert;