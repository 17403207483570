import {Link} from "react-router-dom";
import React from "react";
import {useParams} from "react-router";
import NewNetwork from "./NewNetwork";
import ListNetworks from "./ListNetworks";
import { parse } from 'query-string';
import EditNetwork from "./EditNetwork";

export default ({change_state, change_sublink})=> {

    change_state('mobile_networks');
    change_sublink('list_networks');


    const {action} = useParams();
    // const {query} = parse(window.location.details);








    const getTitle=(link)=>{

        switch (link) {

            case "new_network":
                return 'Add new network';

            case "list_networks":
                return 'List networks';


            case "edit_details":
                return 'Edit network details';


        }


    }


    const switching =()=>{

        switch (action) {



            case "edit_details":

                return <EditNetwork />;


            case "new_network":
                return <NewNetwork/>;



            case "list_networks":
                return <ListNetworks/>;



        }
    }



    return (
        <div className="inbox-wrapper">
    <div >
        <div className="card">
            <div className="card-body">



                <div className="email-head">
                    <div className="email-head-title d-flex align-items-center">
                        Mobile Networks
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="col-lg-3 email-aside border-sm-right">
                        <div className="aside-content">


                            <div className="aside-nav ">
                                <ul className="nav">



                                    <li >

                                        <Link to={"/mobile_networks/list_networks"} className={"nav-link"} >



                                            <button
                                                className={action === 'list_networks'?' btn btn-primary btn-block':' btn btn-outline-primary btn-block'}

                                            >Mobile Networks</button>




                                        </Link>

                                    </li>



                                    <li >

                                        <Link to={"/mobile_networks/new_network"} className={"nav-link"} >



                                            <button
                                                className={action === 'new_network'?' btn btn-primary btn-block':' btn btn-outline-primary btn-block'}

                                            >Add network</button>




                                        </Link>

                                    </li>











                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 email-content">
                        <div className="email-inbox-header">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="email-title mb-2 mb-md-0">
                                        {getTitle(action)}  </div>
                                </div>

                            </div>
                        </div>

                        <div className="email-list">

                            {
                                switching()
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
)
}