import Alert from "../../Alert";
import NumberFormat from "react-number-format";
import React, {useEffect, useState} from "react";
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import Select from 'react-select'
import { useHistory } from 'react-router-dom';
export default ()=>{

    const [routes,setRoutes] = useState([]);
    const [data,setData] = useState({});
    const [sending,setSending] = useState(false);
    const [error,setError] = useState([]);
    const history = useHistory();



    const load_routes=()=>{


        {

            {


                fetch(utils.base_url + 'api/routes/',
                    {
                        method: 'POST',

                        headers: {'Content-Type': 'application/json'},

                    }).then(res => res.json())
                    .then(json => {




                        let newData = []

                        json.map(result=> {

                            newData = [...newData, {
                                value: result.id, label: result.routeName
                            }]

                        })


                        setRoutes(newData);



                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }



    useEffect(()=>{


        load_routes();


        console.log(data);

    },[])





    const handleStatus=(status)=>{

        setError({...error,status_login:status});

    }


    const submits = e => {
        e.preventDefault()


        console.log(JSON.stringify({ data }));



        setSending(true);


        fetch(utils.base_url+'api/add_network/'+ReactSession.get('id'), {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setSending(false);

                history.push("/mobile_networks/list_networks");
                // if(parseInt(json.ResultCode) === 10001)
                // {
                //
                //     setError({
                //         icon:'bell',
                //         header:'Thank you',
                //         class_Name:'success',
                //         status_login:true,
                //         status:true,
                //         message:json.ResultMessage,
                //     });
                //
                //     e.target.reset();
                //     setData({})
                //
                // }else{
                //
                //
                //     setError({
                //         icon:'bell',
                //         header:'Access denied',
                //         class_Name:'danger',
                //         status_login:true,
                //         status:true,
                //         message:json.ResultMessage,
                //     });
                //
                // }




            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    return (<div>

        {
            error.status_login && <Alert message={error.message} icon={error.icon} class_Name={error.class_Name} header={error.header}  showing={error.status_login}  handleStatus={handleStatus} />
        }


        <form className="forms-sample"  onSubmit={submits}>

            <div className="email-compose-fields" data-select2-id={14}>










                <div className="description mt-3">
                    <div className="form-group row py-0">
                        <label className="col-md-2 control-label">Network Name</label>
                        <div className="col-md-10">




                            <input
                                className={" form-control "}
                                placeholder="Network name"
                                value={data.networkname}

                                onChange={(e)=>setData({...data,networkname:e.target.value})}

                            />



                        </div>
                    </div>
                </div>



                <div className="description mt-3">
                    <div className="form-group row py-0">
                        <label className="col-md-2 control-label">Prefix</label>
                        <div className="col-md-10">




                            <NumberFormat
                                className={" form-control "}
                                placeholder="Network prefix e.g 25678"
                                value={data.prefix}

                                onChange={(e)=>setData({...data,prefix:e.target.value})}

                                format="### ##" />



                        </div>
                    </div>
                </div>







                <div className="description mt-3">
                    <div className="form-group row py-0">
                        <label className="col-md-2 control-label">SMS cost</label>
                        <div className="col-md-10">




                            <NumberFormat
                                className={" form-control "}
                                placeholder="Default SMS cost"
                                value={data.default_smscost}
                                onChange={(e)=>setData({...data,default_smscost:e.target.value})}
                                thousandSeparator={','}
                                suffix={'/-'}
                            />




                        </div>
                    </div>
                </div>



                <div className="description mt-3">
                    <div className="form-group row py-0">
                        <label className="col-md-2 control-label">SMS route</label>
                        <div className="col-md-10">


                            <Select options={routes}
                                    onChange={(e)=>
                                    {

                                        console.log(e)
                                        setData({...data,default_routeid:e.value})


                                    }
                                    } />



                        </div>
                    </div>
                </div>








            </div>


            <div className="form-group row pr-2 pl-2">

                <div className="col-md-2">
                </div>

                <div className="col-md-10">
                    <div className="form-group mb-0">






                        {

                            !sending ?
                                <button type={"submit"} className="btn btn-success  btn-space btn-block">Add</button>

                                :
                                <button type={"button"} disabled={true} className="btn  btn-success btn-space btn-block">   Adding....</button>

                        }



                    </div>
                </div>


            </div>

        </form>

    </div>);

}