
import React,{useRef, useState, useEffect, } from 'react';



 // import '../static/dist/css/ace.min.css'
 import '../assets/vendors/core/core.css'
 import '../assets/vendors/mdi/css/materialdesignicons.min.css'
 // import '../assets/vendors/mdi/css/materialdesignicons.min.css'
 import '../assets/fonts/feather-font/css/iconfont.css'
 import '../assets/vendors/flag-icon-css/css/flag-icon.min.css'
 import '../assets/css/demo_5/style.css'

   import bg from '../assets/images/bg.png'
  // import padlock from '../static/assets/image/padlock.png'



// import History from './History'

// import { useHistory } from 'react-router-dom'
import { Redirect, Route } from "react-router";



import {ReactSession} from 'react-client-session';

import utils from "./Utils";
import Alert from "./Alert";

import PasswordStrengthBar from 'react-password-strength-bar';


const  getYear = ()=> {
    console.log(new Date().getFullYear());
    return new Date().getFullYear();
}
//
//
//



// componentWillMount();








const SignIn=()=>{
    document.title = "NobleSMS : Login";
    const [login, setLogin] = useState(true);
    const [signup, setsignUp] = useState('tab-pane  show mh-100 px-3 px-lg-0 pb-3 pt-45');
    // const [create_account, setCreateAccount] = useState([]);
    const [create_account, setCreateAccount] = useState([]);
    const [passwordscore, setPasswordscore] = useState(0);
    const [user, setUser] = useState({});
    const [creating,setCreating] = useState(false);
    const [sending,setSending] = useState(false);
    // const [error,setError] = useState({
    //     status:false,
    //     message:'',
    //     type:'',
    //     icon:'exclamation',
    //     header:'Access denied',
    //     class_Name:'success'
    // });
    const [error,setError] = useState([]);



    useEffect(()=>{

        console.log(user,sending,error, create_account);
        console.log(user,sending,error);
    },[create_account,user]);





    const form = useRef(null);


    ReactSession.setStoreType("localStorage");


    const submits = e => {
        e.preventDefault()


        console.log(JSON.stringify({ user }));



        setSending(true);


        fetch(utils.base_url+'user/login', {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ user }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setSending(false);


                if(json.id)
                {

                    ReactSession.set("is_logged_out", null);
                    ReactSession.set("id", json.id);
                    ReactSession.set("firstname", json.firstname);
                    ReactSession.set("lastname", json.lastname);
                    ReactSession.set("email", json.email);
                    ReactSession.set("role", json.role);
                    ReactSession.set("picture_url", json.picture_url);





                    window.location.reload();


                }else{

                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                }


                console.log(json.ResultCode);
                console.log(json.ResultMessege);

                //setUser(json.user)

            })
            .catch((e)=>{

                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    const createAccount = e => {
        e.preventDefault()


        setSending(true);
        setCreating(true);


        fetch(utils.base_url+'user/create_user', {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ create_account }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {

                setSending(false);
                setCreating(false);


                if(json.id)
                {

                    ReactSession.set("is_logged_out", null);
                    ReactSession.set("id", json.id);
                    ReactSession.set("firstname", json.firstname);
                    ReactSession.set("lastname", json.lastname);
                    ReactSession.set("email", json.email);
                    ReactSession.set("role", json.role);
                     ReactSession.set("picture_url", json.picture_url);




                    window.location.reload();


                }else{


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_create_account:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                }


                console.log(json.ResultCode);
                console.log(json.ResultMessege);

                //setUser(json.user)

            })
            .catch((e)=>{
                setCreating(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    console.log(     ReactSession.get("username"));



    const getScore=(e)=>{
        setPasswordscore(e);
     console.log(e)   ;
    }


    const handleStatus=(status)=>{

        setError({...error,status_login:status});

    }


    const handleStatusCreate=(status)=>{

        setError({...error,status_create_account:status});

    }

    return (
        <div style={{
            backgroundImage: "url(" + bg+ ")",

            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <div>

                <div className="main-wrapper">
                    <div className="page-wrapper full-page">
                        <div className="page-content d-flex align-items-center justify-content-center">
                            <div className="row w-100 mx-0 auth-page">
                                <div className="col-md-9 mx-auto">
                                    <div className="card">
                                        <div className="row">


                                            <div className="col-md-4 pr-md-0">
                                                <div className="auth-left-wrapper">
                                                </div>
                                            </div>


                                            <div className="col-md-8 pl-md-0">



                                                {
                                                    login ?
                                                        <div className="auth-form-wrapper px-4 py-5">
                                                            <a href="#" className="noble-ui-logo d-block mb-2">Noble<span>SMS</span></a>
                                                            <h5 className="text-muted font-weight-normal mb-4">Welcome back! Log in to your account.</h5>



                                                            {
                                                                error.status_login && <Alert message={error.message} icon={error.icon} class_Name={error.class_Name} header={error.header}  showing={error.status_login}  handleStatus={handleStatus} />
                                                            }


                                                            <form className="forms-sample"  onSubmit={submits}>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                                                    <input name={"login-email"} type="email" onChange={(e)=> setUser({...user,email:e.target.value})} required={"required"} className="form-control" id="exampleInputEmail1" placeholder="Email" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputPassword1">Password</label>
                                                                    <input name={"login-password"} type="password"  onChange={(e)=> setUser({...user,password:e.target.value})} required={"required"} className="form-control" id="exampleInputPassword1"  placeholder="Password" />
                                                                </div>

                                                                <div className="mt-3">


                                                                    {

                                                                        !sending ?
                                                                            <button type={"submit"} className="btn btn-block btn-primary mr-2 mb-2 mb-md-0 text-white">Login</button>

                                                                            :
                                                                            <button type={"button"} disabled={true} className="btn btn-block btn-success mr-2 mb-2 mb-md-0 text-white">   Authorizing....</button>

                                                                    }



                                                                </div>

                                                                <div className={"mt-3"}>
                                                                    Not a member yet?   <a
                                                                    href={"#"}
                                                                    onClick={()=>{

                                                                        setLogin(false);
                                                                        // setUser({});

                                                                    }
                                                                    }
                                                                    className="text-primary"> Register Now</a>
                                                                </div>

                                                            </form>
                                                        </div>
                                                        :
                                                        <div className="auth-form-wrapper px-4 py-5">
                                                            <a href="#" className="noble-ui-logo d-block mb-2">Noble<span>SMS</span></a>
                                                            <h5 className="text-muted font-weight-normal mb-4">Create a free Account.</h5>



                                                            {
                                                                error.status_create_account && <Alert message={error.message} icon={error.icon} class_Name={error.class_Name} header={error.header}  showing={error.status_create_account}  handleStatus={handleStatusCreate} />
                                                            }


                                                            <form className="forms-sample"  onSubmit={createAccount}>



                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">First name</label>
                                                                    <input name={"create-firstname"} type="text"  onChange={(e)=> setCreateAccount({...create_account,first_name:e.target.value})} required={"required"} className="form-control" placeholder="First name" />
                                                                </div>

                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">Last name</label>
                                                                    <input name={"create-last"} type="text"  onChange={(e)=> setCreateAccount({...create_account,last_name:e.target.value})} required={"required"} className="form-control" placeholder="Last name" />
                                                                </div>



                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                                                    <input name={"create-email"} type="email"  onChange={(e)=> setCreateAccount({...create_account,email:e.target.value})} required={"required"} className="form-control" placeholder="Email" />
                                                                </div>


                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputPassword1">Password</label>
                                                                    <input name={"create-password"} type="password" onChange={(e)=> setCreateAccount({...create_account,password:e.target.value})} required={"required"} className="form-control"   placeholder="Password" />
                                                                    <PasswordStrengthBar password={create_account.password} />

                                                                </div>


                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputPassword1">Confirm Password</label>
                                                                    <input type="password"  onChange={(e)=> setCreateAccount({...create_account,confirm_password:e.target.value})} required={"required"} className="form-control" id="exampleInputPassword1"  placeholder="Confirm Password" />
                                                                    <PasswordStrengthBar password={create_account.confirm_password} onChangeScore={getScore}/>

                                                                </div>





                                                                <div className="mt-3">


                                                                    {

                                                                        passwordscore >= 2 && create_account.confirm_password === create_account.password && create_account.password.length > 0 ?

                                                                            !creating ?
                                                                            <button type={"submit"} className="btn btn-block btn-success mr-2 mb-2 mb-md-0 ">Sign up</button>

                                                                            :
                                                                            <button type={"button"} disabled={true} className="btn btn-block btn-success mr-2 mb-2 mb-md-0 text-white">   Authorizing....</button>

                                                                            :
                                                                            <>
                                                                            </>

                                                                    }




                                                                </div>

                                                                <div className={"mt-3"}>
                                                                    Already a member ?   <a

                                                                    href={"#"}
                                                                    onClick={()=>{

                                                                        setLogin(true);


                                                                        // setCreateAccount({});

                                                                    }
                                                                    }
                                                                    className="text-success bold"> Sign In</a>
                                                                </div>

                                                            </form>
                                                        </div>

                                                }












                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>

    );






}








export default SignIn;