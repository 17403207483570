import logo from './logo.svg';
// import './App.css';

import Customer from "./components/Customer/Customer";
// import Admin from "./components/Admin/Admin";
import {ReactSession} from 'react-client-session';
import SignIn from "./components/SignIn";

import React from "react";

import {Link,HashRouter, BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Payment from "./components/Customer/Payment";
import User from "./components/Customer/User";
import Admin from "./components/Customer/Admin";

function App() {


    ReactSession.setStoreType("localStorage");
    const isloggedin = ReactSession.get('id');
    const role = ReactSession.get('role');

    console.log(window.location.href);

    // //
    // ReactSession.set("id",null);
    // ReactSession.set("role",null);
    // ReactSession.set("picture_url",null);

    console.log(isloggedin,role)

    return (
        <div className="App">

            <HashRouter>

                <Switch>

                    <Route exact path={"/payments/:page"}>
                        <Payment/>
                    </Route>

                    <Route exact path={"*"}>
                        {

                            !isloggedin ? <SignIn  /> :
                                role === 'user'?
                                    <User  />:
                                    <Admin  />


                        }
                    </Route>


                </Switch>


            </HashRouter>

        </div>
    );
}

export default App;
