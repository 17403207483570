import {HashRouter, Link, useLocation, useParams} from "react-router-dom";

import '../../../assets/vendors/select2/select2.min.css'
import '../../../assets/vendors/simplemde/simplemde.min.css'
import {Helmet} from "react-helmet";
import React,{useState, useEffect} from "react";
import {Input} from "react-input";
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import Alert from "../../Alert";
import PasswordStrengthBar from "react-password-strength-bar";

export default ()=>{


    const [login, setLogin] = useState(true);
    const [passwordscore, setPasswordscore] = useState(0);
    const [creating,setCreating] = useState(false);
    const [create_account, setCreateAccount] = useState([]);
    const [data,setData] = useState({});
    const [sending,setSending] = useState(false);
    const [error,setError] = useState([]);
    const {id} = useParams();
    // sublink('edit_details')
    // change_state('users')

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    useEffect(()=>{

        let data = JSON.parse(query.get('details'));



        // setData({...data,default_routeid:data})

        setCreateAccount(data);


        console.log('Edit users details');

    },[])





    const pre_load_data = (id) => {






        fetch(utils.base_url+'user/user_details/'+id, {
            crossDomain:true,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(data => {




                console.log(data);

                // json.map((data)=>
                // {

                    setCreateAccount({
                        first_name:data.firstname,
                        last_name:data.lastname,
                        id:data.id,
                        email:data.email,

                    });

                // })




            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    const createAccount = e => {
        e.preventDefault()


        setSending(true);
        setCreating(true);


        fetch(utils.base_url+'user/update_account/'+ReactSession.get('id'), {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ create_account }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {

                setSending(false);
                setCreating(false);


                if(parseInt(json.ResultCode) === 10001)
                {


                    setError({
                        icon:'info',
                        header:'Thank you',
                        class_Name:'success',
                        status_create_account:true,
                        status:true,
                        message:json.ResultMessage,
                    });




                    // ReactSession.set("is_logged_out", null);
                    // ReactSession.set("id", json.id);
                    // ReactSession.set("firstname", json.firstname);
                    // ReactSession.set("lastname", json.lastname);
                    // ReactSession.set("email", json.email);
                    // ReactSession.set("role", json.role);
                    // ReactSession.set("picture_url", json.picture_url);
                    //
                    //
                    //
                    //
                    // window.location.reload();

                    //setCreateAccount({...create_account,email:""})
e.target.reset();



                }else{


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_create_account:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                }


                console.log(json.ResultCode);
                console.log(json.ResultMessege);

                //setUser(json.user)

            })
            .catch((e)=>{
                setCreating(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    console.log(     ReactSession.get("username"));



    const getScore=(e)=>{
        setPasswordscore(e);
        console.log(e)   ;
    }


    const handleStatus=(status)=>{

        setError({...error,status_login:status});

    }


    const handleStatusCreate=(status)=>{

        setError({...error,status_create_account:status});

    }






    return (

            <div className="row inbox-wrapper" data-select2-id={20}>
                <div className="col-lg-12" data-select2-id={19}>
                    <div className="col-lg-12 " data-select2-id={18}>
                        <div  data-select2-id={17}>
                            <div className="row" data-select2-id={16}>

                                <div className="col-lg-12 email-content" data-select2-id={15}>
                                    {/*<div className="email-head">*/}
                                        {/*<div className="email-head-title d-flex align-items-center">*/}
                                            {/*<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit icon-md mr-2"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>*/}
                                            {/*Edit User details*/}
                                        {/*</div>*/}
                                    {/*</div>*/}





                                    {
                                        error.status_create_account && <Alert message={error.message} icon={error.icon} class_Name={error.class_Name} header={error.header}  showing={error.status_create_account}  handleStatus={handleStatusCreate} />
                                    }


                                    {
                                        create_account && <form className="forms-sample"  onSubmit={createAccount}>


                                            <div className="email-compose-fields" data-select2-id={14}>



                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">First name</label>
                                                    <input value={create_account.firstname} name={"create-firstname"} type="text"  onChange={(e)=> setCreateAccount({...create_account,first_name:e.target.value})} required={"required"} className="form-control" placeholder="First name" />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Last name</label>
                                                    <input value={create_account.lastname} name={"create-last"} type="text"  onChange={(e)=> setCreateAccount({...create_account,last_name:e.target.value})} required={"required"} className="form-control" placeholder="Last name" />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                                    <input name={"create-email"} value={create_account.email} readOnly type="email"  onChange={(e)=> setCreateAccount({...create_account,email:e.target.value})} required={"required"} className="form-control" placeholder="Email" />
                                                </div>


                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Password</label>
                                                    <input name={"create-password"} type="password" onChange={(e)=> setCreateAccount({...create_account,new_password:e.target.value})} required={"required"} className="form-control"   placeholder="New Password" />
                                                    <PasswordStrengthBar password={create_account.new_password} />

                                                </div>


                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Confirm Password</label>
                                                    <input type="password"  onChange={(e)=> setCreateAccount({...create_account,confirm_new_password:e.target.value})} required={"required"} className="form-control" id="exampleInputPassword1"  placeholder="Confirm New Password" />
                                                    <PasswordStrengthBar password={create_account.confirm_new_password} onChangeScore={getScore}/>

                                                </div>





                                                <div className="mt-3">


                                                    {

                                                        passwordscore >= 2 && create_account.confirm_new_password === create_account.new_password && create_account.new_password.length > 0 ?

                                                            !creating ?
                                                                <button type={"submit"} className="btn btn-block btn-success mr-2 mb-2 mb-md-0 ">Save changes</button>

                                                                :
                                                                <button type={"button"} disabled={true} className="btn btn-block btn-success mr-2 mb-2 mb-md-0 text-white">   Authorizing....</button>

                                                            :
                                                            <>
                                                            </>

                                                    }




                                                </div>


                                            </div>

                                        </form>
                                    }






                                </div>
                            </div>
                        </div>
                    </div>
                </div>





            </div>


    );
}