import {HashRouter, Link} from "react-router-dom";

import '../../assets/vendors/select2/select2.min.css'
import '../../assets/vendors/simplemde/simplemde.min.css'
import {Helmet} from "react-helmet";
import React,{useState, useEffect} from "react";
import {Input} from "react-input";
import utils from "../Utils";
import {ReactSession} from "react-client-session";
import Alert from "../Alert";

import PasswordStrengthBar from 'react-password-strength-bar';


export default ({change_state, sublink})=>{


    const [data,setData] = useState({});
    const [sending,setSending] = useState(false);
    const [error,setError] = useState([]);

    const [create_account, setCreateAccount] = useState([]);
    const [passwordscore, setPasswordscore] = useState(0);

    sublink('change_password')
    change_state('change_password')




    const getScore=(e)=>{
        setPasswordscore(e);
        console.log(e)   ;
    }


    const handleStatus=(status)=>{

        setError({...error,status_login:status});

    }


    const submits = e => {
        e.preventDefault()


        console.log(JSON.stringify({ create_account }));



        setSending(true);


        fetch(utils.base_url+'user/change_password/'+ReactSession.get('id'), {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ create_account }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setSending(false);


                if(parseInt(json.ResultCode) === 10001)
                {



                    setError({
                        icon:'bell',
                        header:'Thank you',
                        class_Name:'success',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });

              e.target.reset();


                    setCreateAccount({...create_account,old_password:""})
                    setCreateAccount({...create_account,password:""})
                    setCreateAccount({...create_account,confirm_password:""})

                }else{


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                }



                //setUser(json.user)

            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };








    useEffect(()=>{

        console.log(data);

    },[data])

    return (

            <div className="row inbox-wrapper" data-select2-id={20}>
                <div className="col-lg-12" data-select2-id={19}>
                    <div className="card" data-select2-id={18}>
                        <div className="card-body" data-select2-id={17}>
                            <div className="row" data-select2-id={16}>

                                <div className="col-lg-12 email-content" data-select2-id={15}>
                                    <div className="email-head">
                                        <div className="email-head-title d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit icon-md mr-2"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                            Change your login password
                                        </div>
                                    </div>




                                    {
                                        error.status_login && <Alert message={error.message} icon={error.icon} class_Name={error.class_Name} header={error.header}  showing={error.status_login}  handleStatus={handleStatus} />
                                    }


                                    <form className="forms-sample"  onSubmit={submits}>

                                    <div className="email-compose-fields" data-select2-id={14}>











                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Old Password</label>
                                            <input value={create_account.old_password} name={"create-password"} type="password" onChange={(e)=> setCreateAccount({...create_account,old_password:e.target.value})} required={"required"} className="form-control"   placeholder="Your Old Password" />
                                            <PasswordStrengthBar password={create_account.old_password} />

                                        </div>



                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Password</label>
                                            <input  value={create_account.password}  name={"create-password"} type="password" onChange={(e)=> setCreateAccount({...create_account,password:e.target.value})} required={"required"} className="form-control"   placeholder="New Password" />
                                            <PasswordStrengthBar password={create_account.password} />

                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Confirm Password</label>
                                            <input type="password" value={create_account.confirm_password}  onChange={(e)=> setCreateAccount({...create_account,confirm_password:e.target.value})} required={"required"} className="form-control" id="exampleInputPassword1"  placeholder="Confirm New Password" />
                                            <PasswordStrengthBar password={create_account.confirm_password} onChangeScore={getScore}/>

                                        </div>


                                    </div>


                                    <div className="form-group row pr-2 pl-2">



                                            <div className="col-md-12">
                                                <div className="form-group mb-0">




                                                    {

                                                        passwordscore >= 2 && create_account.confirm_password === create_account.password && create_account.password.length > 0 ?

                                                            !sending ?
                                                            <button type={"submit"} className="btn btn-success btn-space btn-block">Send</button>

                                                            :
                                                            <button type={"button"} disabled={true} className="btn btn-success btn-space btn-block">   Sending....</button>
                                                            :
                                                            <>
                                                            </>
                                                    }



                                                </div>
                                            </div>


                                    </div>

                                    </form>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>


    );
}