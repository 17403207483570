import Select from 'react-select'
import React, {useEffect, useState} from "react";
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import Alert from "../../Alert";
import Swal from "sweetalert2";
import DateTimePicker from 'react-datetime-picker';
export default ({change_state})=>{

    change_state('send_message');


    const [data,setData] = useState([]);
    const [ListId,setListId] = useState([]);
    const [Search,setSearch] = useState([])
    const [sending,setSending] = useState(false);
    const [error,setError] = useState([]);




    const handleStatus=(status)=>{

        setError({...error,status_login:status});

    }


    const submits = e => {
        e.preventDefault()


        console.log(JSON.stringify({ data }));



        setSending(true);


        fetch(utils.base_url+'messaging/send_user_message/'+ReactSession.get('id'), {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setSending(false);

                // history.push("/mobile_networks/list_networks");
                if(parseInt(json.ResultCode) === 10001)
                {




                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })



                    setError({
                        icon:'bell',
                        header:'Thank you',
                        class_Name:'success',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });

                    e.target.reset();
                    setData({})



                    load_contact_lists()

                }else{


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });

                }




            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };





    const load_contact_lists=()=>{


        {


            {


                fetch(utils.base_url + 'api/list_contacts/'+ReactSession.get('id')+"/0",
                    {
                        method: 'POST',
                        body: JSON.stringify({ Search }),
                        headers: {'Content-Type': 'application/json'},


                    }).then(res => res.json())
                    .then(json => {


                        console.log(json)


                        let newData = [];

                        json.map(result=> {

                            newData = [...newData, {
                                value: result.contact_list.id, label: result.contact_list.list_name
                            }]


                            // if(result.id ===JSON.parse(query.get('details')).default_routeid )
                            // {
                            //     setRoutes({
                            //         value: result.id, label: result.routeName
                            //     })
                            //
                            // }


                        })

                        console.log(newData)


                            setListId(newData);





                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }






    useEffect(()=>{


       load_contact_lists()


    },[]);



    const getMessageLength=(message)=>{

        let length = message.length;

        let char = parseInt(length/159)
        char++;

        return char+" | "+message.length



    }




    return (<div>

        <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">





                    <div className="card-body">
                        <h6 className="card-title">Send Message</h6>


                        {
                            error.status_login && <Alert message={error.message} icon={error.icon} class_Name={error.class_Name} header={error.header}  showing={error.status_login}  handleStatus={handleStatus} />
                        }



                        <form onSubmit={submits}>


                            <div className="form-group">
                                <label htmlFor="exampleInputText1">From</label>
                                <input type="text" className="form-control" value={data.from} onChange={(e)=>
                                { setData({...data,from:e.target.value})}
                                } id="exampleInputText1"  placeholder="Maximum 11 characters" required={"required"} maxLength={"11"}/>
                            </div>


                            <div className="form-group">
                                <label htmlFor="exampleInputEmail3">To</label>
                                <textarea  className="form-control" value={data.to} onChange={(e)=>
                                { setData({...data,to:e.target.value})}
                                } placeholder={"Paste one number per line.  All Numbers should include the country code without the (+) sign"} id="exampleFormControlTextarea1" rows={5} defaultValue={""} />
                            </div>







                            <div className="form-group">
                                <label htmlFor="exampleInputNumber1">Add List</label>







                                            <Select


                                                isMulti
                                                 options={ListId}
                                                    onChange={(e)=>
                                                    {

                                                        console.log('list_details',data)
                                                        setData({...data,listid:e})


                                                    }
                                                    } />





                            </div>


                            <span className={"text-danger mt-2"}>As a requirement by UCC, all messages to Uganda unless registered will be appended with "DND *196#" (10 Characters)</span>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail3">Message</label>
                                <textarea required={"required"} className="form-control" value={data.message}  onChange={(e)=>
                                { setData({...data,message:e.target.value})}
                                } placeholder="Type your message here!" id="exampleFormControlTextarea1" rows={5} defaultValue={""} />


                                {

                                    data.message &&


                                    getMessageLength(  data.message)




                                }


                            </div>





                            <div className="form-group">
                                <label htmlFor="exampleInputPassword3">Schedule date</label>
                                <input type="date" value={data.schedule_date}  onChange={(e)=>
                                { setData({...data,schedule_date:e.target.value})}
                                }


                                       className="form-control" id="exampleInputPassword3"  placeholder="Enter schedule date" />
                            </div>


                            <div className="form-group">
                                <label htmlFor="exampleInputPassword3">Schedule Time</label>
                                <input type="time" value={data.schedule_time}  onChange={(e)=>
                                { setData({...data,schedule_time:e.target.value})}
                                }



                                       min="00:00" max="23:59"
                                       pattern="[0-9]{2}:[0-9]{2}"


                                       className="form-control" id="exampleInputPassword3"  placeholder="Enter schedule time" />
                            </div>




                            {

                                !sending ?
                                    <button type={"submit"} className="btn btn-success  btn-space btn-block">Send Now</button>

                                    :
                                    <button type={"button"} disabled={true} className="btn  btn-success btn-space btn-block">   Sending....</button>

                            }




                        </form>
                    </div>
                </div>
            </div>
        </div>


    </div>)
}