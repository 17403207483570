import {useParams} from "react-router";
import React,{useRef, useState, useEffect, } from 'react';

import '../../assets/vendors/core/core.css'
import '../../assets/vendors/bootstrap-datepicker/bootstrap-datepicker.min.css'
import '../../assets/vendors/mdi/css/materialdesignicons.min.css'
// import '../assets/vendors/mdi/css/materialdesignicons.min.css'
import '../../assets/fonts/feather-font/css/iconfont.css'
import '../../assets/vendors/flag-icon-css/css/flag-icon.min.css'
import '../../assets/css/demo_5/style.css'
import logo from '../../assets/images/profile-cover.jpg'
import face from '../../assets/images/faces/face1.jpg'
import mtn from '../../assets/images/icons/mtn.png'
import airtel from '../../assets/images/icons/airtel-logo.png'
import card from '../../assets/images/icons/visa-mastercard.png'
import utils from "../Utils";
import {ReactSession} from "react-client-session";
import NumberFormat from "react-number-format";
import Swal from 'sweetalert2'
import Helmet from "react-helmet";
import MetaTags from 'react-meta-tags';
export default ()=>{


    const [CardUrl,setCardUrl] = useState(null);
    const [CardResponse,setCardResponse] = useState(null);
    const [AirtelResponse,setAirtelResponse] = useState(null);
    const [mtnResponse,setmtnResponse] = useState(null);
    const [mtndata,setMtnData] = useState({});
    const [Airteldata,setAirtelData] = useState({});
    const [Carddata,setCardData] = useState({});
    const [sending,setSending] = useState(false);
    const [sending_card,setsending_card] = useState(false);
    const [sending_airtel,setsending_airtel] = useState(false);
    const [sending_mtn,setsending_mtn] = useState(false);
    const [error,setError] = useState([]);

    const [cardPanel,setCardPanel] = useState(-1);
    const [airtelPanel,setAirtelPanel] = useState(-1);
    const [mtnPanel,setMtnPanel] = useState(-1);
    const [pageData,setPageData] = useState({});
    const [isValid,sIsValid] = useState(false);
    const {page} = useParams();




    const process_mtn = e => {
        e.preventDefault()


        console.log(JSON.stringify({ mtndata }));

        setmtnResponse('Your request has been submitted. Please wait for a mobile money PIN request to proceed or dial *165# to approve this payment');

        setsending_mtn(true);


        fetch(utils.base_url+'projects/process_mtn/'+pageData.id, {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ mtndata }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setsending_mtn(false);
                // setmtnResponse('Your request is in progress. Dial *165# to approve this transaction or try again');
                setmtnResponse(json.ResultMessage);


                if(parseInt(json.ResultCode) === 10001)
                {


                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'success',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })


                    setError({
                        icon:'bell',
                        header:'Thank you',
                        class_Name:'success',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });

                    e.target.reset();


                    setMtnData({})

                }else{


                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })

                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                    e.target.reset();


                    setMtnData({})
                }



                //setUser(json.user)

            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    const process_airtel = e => {
        e.preventDefault()


        console.log(JSON.stringify({ Airteldata }));

        setAirtelResponse('Your request has been submitted');

        setsending_airtel(true);


        fetch(utils.base_url+'projects/process_airtel/'+pageData.id, {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ Airteldata }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setsending_airtel(false);
                // setmtnResponse('Your request is in progress. Dial *165# to approve this transaction or try again');
                setAirtelResponse(json.ResultMessage);


                if(parseInt(json.ResultCode) === 10001)
                {



                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'success',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })



                    setError({
                        icon:'bell',
                        header:'Thank you',
                        class_Name:'success',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });

                    e.target.reset();


                    setAirtelData({})

                }else{




                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                    e.target.reset();


                    setAirtelData({})
                }



                //setUser(json.user)

            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };

    const process_card = e => {
        e.preventDefault()


        console.log(JSON.stringify({ Carddata }));



        setsending_card(true);


        fetch(utils.base_url+'projects/process_card/'+pageData.id, {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ Carddata }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setsending_card(false);
                // setmtnResponse('Your request is in progress. Dial *165# to approve this transaction or try again');
                setCardResponse(json.ResultMessage);




                if(parseInt(json.ResultCode) === 10001)
                {




                    setCardUrl(json.Data.link);


                    e.target.reset();


                    setCardData({})

                }else{




                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                    e.target.reset();


                    setAirtelData({})
                }



                //setUser(json.user)

            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    const load_page_info = (page)=>{




        fetch(utils.base_url + 'projects/get_project_details/'+page,
            {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(json => {






                if(json.id)
                {

                    setPageData(json);

                    sIsValid(true);

                }else{

                    setPageData({})
                    sIsValid(false);

                }







            })
            .catch((e) => {

                console.log(e);

            })










    }
    console.log(page);
    useEffect(()=>{

        load_page_info(page);


    },[]);

    return (
        <div>


            {
             isValid &&    <div>

                 <MetaTags>

                     <title>{pageData.displayName}</title>
                     <meta name="description" content={pageData.bio} />
                     <meta property="og:title" content={pageData.displayName} />
                     <meta property="og:image" content={utils.picture_url+pageData.picture_url} />


                     <meta name="og:title" content={pageData.displayName}/>
                     <meta name="og:description" content={pageData.bio} />
                     <meta name="og:site_name" content={pageData.displayName+" - contributions"}/>

                     <meta name="og:url" content={window.location.href}/>


                     <meta name="twitter:site" content={pageData.displayName+" - contributions"}/>
                     <meta name="twitter:creator" content={pageData.displayName}/>
                     <meta name="twitter:title" content={pageData.displayName}/>
                     <meta name="twitter:image" content={utils.picture_url+pageData.picture_url}/>


                 </MetaTags>



                    <div className="main-wrapper">

                        <div className="page-wrapper">
                            <div className="page-content">
                                <div className="profile-page tx-13">
                                    <div className="row">

                                    </div>
                                    <div className="row profile-body">
                                        {/* left wrapper start */}
                                        <div className="d-none d-md-block col-md-4 col-xl-3 left-wrapper">

                                        </div>
                                        {/* left wrapper end */}
                                        {/* middle wrapper start */}
                                        <div className="col-md-8 col-xl-6 middle-wrapper">
                                            <div className="row">
                                                <div className="col-md-12 grid-margin">
                                                    <div className="card rounded">
                                                        <div className="card-header">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="d-flex align-items-center">
                                                                    <img className="img-sm " src={utils.picture_url+pageData.picture_url} alt="" />
                                                                    <div className="ml-2">
                                                                        <p>{pageData.displayName}</p>
                                                                        <p className="tx-11 text-muted">Since {utils.getTimestring(pageData.created_at)}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <p className="mb-3 tx-14">{pageData.bio}</p>

                                                            {
                                                                mtnResponse && <div className="alert alert-warning text-center" role="alert">
                                                                    {mtnResponse}
                                                                </div>
                                                            }

                                                            <div >
                                                                <div id="accordion" className="accordion" role="tablist">



                                                                    <div className="card">
                                                                        <div className="card-header" role="tab" id="headingOne">
                                                                            <h6 className="mb-0">
                                                                                <span data-toggle="collapse" style={{cursor:'pointer'}} onClick={()=>{

                                                                                    setMtnPanel(parseInt(mtnPanel)*-1);
                                                                                    setAirtelPanel(-1);
                                                                                    setCardPanel(-1);

                                                                                }
                                                                                } aria-expanded="true" aria-controls="collapseOne" className>


                                                                                  <img className="img-fluid mr-3" src={mtn} alt="" width={"50"} />    MTN Mobile Money
                                                                                </span>
                                                                            </h6>
                                                                        </div>
                                                                        <div id="collapseOne" className={mtnPanel > 0?"collapse show":"collapse"}  role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" style={{}}>
                                                                            <div className="card-body">




                                                                                <form className="forms-sample" onSubmit={process_mtn} >

                                                                                    <div className="email-compose-fields" data-select2-id={14}>











                                                                                        <div className="description">
                                                                                            <div className="form-group row py-0">
                                                                                                <label className="col-md-2 control-label">Name</label>
                                                                                                <div className="col-md-10">




                                                                                                    <input
                                                                                                        required={"required"}
                                                                                                        className={" form-control "}
                                                                                                        placeholder="Your name here"
                                                                                                        value={mtndata.name}
                                                                                                        onChange={(e)=>setMtnData({...mtndata,name:e.target.value})}

                                                                                                      />



                                                                                                </div>
                                                                                            </div>
                                                                                        </div>



                                                                                        <div className="description mt-3">
                                                                                            <div className="form-group row py-0">
                                                                                                <label className="col-md-2 control-label">Mobile</label>
                                                                                                <div className="col-md-10">




                                                                                                    <NumberFormat
                                                                                                        required={"required"}
                                                                                                        className={" form-control "}
                                                                                                        placeholder="Your Phone number e.g 0773956277"
                                                                                                        value={mtndata.phone}
                                                                                                        onChange={(e)=>setMtnData({...mtndata,phone:e.target.value})}

                                                                                                        format="#### ### ###" />



                                                                                                </div>
                                                                                            </div>
                                                                                        </div>







                                                                                        <div className="description mt-3">
                                                                                            <div className="form-group row py-0">
                                                                                                <label className="col-md-2 control-label">Amount</label>
                                                                                                <div className="col-md-10">




                                                                                                    <NumberFormat
                                                                                                        required={"required"}
                                                                                                        className={" form-control "}
                                                                                                        placeholder="Enter amount here"
                                                                                                        value={mtndata.amount}
                                                                                                        onChange={(e)=>setMtnData({...mtndata,amount:e.target.value})}
                                                                                                        thousandSeparator={','}
                                                                                                        suffix={'/-'}
                                                                                                    />




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>




                                                                                        <div className="description mt-3">
                                                                                            <div className="form-group row py-0">
                                                                                                <label className="col-md-2 control-label">Reason</label>
                                                                                                <div className="col-md-10">




                                                                                                    <input
                                                                                                        required={"required"}
                                                                                                        className={" form-control "}
                                                                                                        placeholder="Reason for payment"
                                                                                                        value={mtndata.reason}
                                                                                                        onChange={(e)=>setMtnData({...mtndata,reason:e.target.value})}

                                                                                                    />



                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>


                                                                                    <div className="form-group row pr-2 pl-2">

                                                                                        <div className="col-md-2">
                                                                                        </div>

                                                                                        <div className="col-md-10">
                                                                                            <div className="form-group mb-0">






                                                                                                {

                                                                                                    !sending_mtn ?
                                                                                                        <button type={"submit"} className="btn btn-success  btn-space btn-block">Pay</button>

                                                                                                        :
                                                                                                        <button type={"button"} disabled={true} className="btn  btn-success btn-space btn-block">   Authorizing....</button>

                                                                                                }



                                                                                            </div>
                                                                                        </div>


                                                                                    </div>

                                                                                </form>


                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        AirtelResponse && <div className="alert alert-danger text-center" role="alert">
                                                                            {AirtelResponse}
                                                                        </div>
                                                                    }


                                                                    <div className="card">
                                                                        <div className="card-header" role="tab" id="headingOne">
                                                                            <h6 className="mb-0">
                                                                                <span data-toggle="collapse" style={{cursor:'pointer'}} onClick={()=>{

                                                                                    setAirtelPanel(parseInt(airtelPanel)*-1);
                                                                                    setMtnPanel(-1);
                                                                                    setCardPanel(-1);
                                                                                }
                                                                                } aria-expanded="true" aria-controls="collapseOne" className>


                                                                                  <img className="img-fluid mr-3" src={airtel} alt="" width={"50"} />    Airtel Money
                                                                                </span>
                                                                            </h6>
                                                                        </div>
                                                                        <div id="collapseOne" className={airtelPanel > 0?"collapse show":"collapse"}  role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" style={{}}>
                                                                            <div className="card-body">



                                                                                {
                                                                                   ! AirtelResponse &&
                                                                                   <div className="alert alert-danger text-center" role="alert">
                                                                                     Dial *185*3# to initiate a transaction withdraw from your mobile wallet
                                                                                    </div>
                                                                                }



                                                                                <form className="forms-sample" onSubmit={process_airtel}  >

                                                                                    <div className="email-compose-fields" data-select2-id={14}>





                                                                                        <div className="description">
                                                                                            <div className="form-group row py-0">
                                                                                                <label className="col-md-3 control-label">Name</label>
                                                                                                <div className="col-md-9">




                                                                                                    <input
                                                                                                        required={"required"}
                                                                                                        className={" form-control "}
                                                                                                        placeholder="Your name here"
                                                                                                        value={Airteldata.name}
                                                                                                        onChange={(e)=>setAirtelData({...Airteldata,name:e.target.value})}

                                                                                                    />



                                                                                                </div>
                                                                                            </div>
                                                                                        </div>




                                                                                        <div className="description mt-3">
                                                                                            <div className="form-group row py-0">
                                                                                                <label className="col-md-3 control-label">Mobile</label>
                                                                                                <div className="col-md-9">




                                                                                                    <NumberFormat
                                                                                                        required={"required"}
                                                                                                        className={" form-control "}
                                                                                                        placeholder="Your Phone number e.g 0703956277"
                                                                                                        value={Airteldata.phone}
                                                                                                        onChange={(e)=>setAirtelData({...Airteldata,phone:e.target.value})}

                                                                                                        format="#### ### ###" />



                                                                                                </div>
                                                                                            </div>
                                                                                        </div>







                                                                                        <div className="description mt-3">
                                                                                            <div className="form-group row py-0">
                                                                                                <label className="col-md-3 control-label">Secret Code</label>
                                                                                                <div className="col-md-9">




                                                                                                    <NumberFormat
                                                                                                        required={"required"}
                                                                                                        className={" form-control "}
                                                                                                        placeholder="Enter secret Code"
                                                                                                        value={Airteldata.secret_code}
                                                                                                        onChange={(e)=>setAirtelData({...Airteldata,secret_code:e.target.value})}
                                                                                                        format="### ###"
                                                                                                    />




                                                                                                </div>
                                                                                            </div>
                                                                                        </div>



                                                                                        <div className="description mt-3">
                                                                                            <div className="form-group row py-0">
                                                                                                <label className="col-md-3 control-label">Reason</label>
                                                                                                <div className="col-md-9">




                                                                                                    <input
                                                                                                        required={"required"}
                                                                                                        className={" form-control "}
                                                                                                        placeholder="Your reason for payment"
                                                                                                        value={Airteldata.reason}
                                                                                                        onChange={(e)=>setAirtelData({...Airteldata,reason:e.target.value})}

                                                                                                    />



                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>


                                                                                    <div className="form-group row pr-2 pl-2">

                                                                                        <div className="col-md-2">
                                                                                        </div>

                                                                                        <div className="col-md-10">
                                                                                            <div className="form-group mb-0">






                                                                                                {

                                                                                                    !sending_airtel ?
                                                                                                        <button type={"submit"} className="btn btn-success  btn-space btn-block">Send</button>

                                                                                                        :
                                                                                                        <button type={"button"} disabled={true} className="btn  btn-success btn-space btn-block">   Requesting....</button>

                                                                                                }



                                                                                            </div>
                                                                                        </div>


                                                                                    </div>

                                                                                </form>


                                                                            </div>
                                                                        </div>
                                                                    </div>






                                                                    <div className="card">
                                                                        <div className="card-header" role="tab" id="headingOne">
                                                                            <h6 className="mb-0">
                                                                                <span data-toggle="collapse" style={{cursor:'pointer'}} onClick={()=>{

                                                                                    setCardPanel(parseInt(cardPanel)*-1);
                                                                                    setAirtelPanel(-1);
                                                                                    setMtnPanel(-1);
                                                                                }
                                                                                } aria-expanded="true" aria-controls="collapseOne" className>


                                                                                  <img className="img-fluid mr-3" src={card} alt="" width={"50"} />    Card
                                                                                </span>
                                                                            </h6>
                                                                        </div>
                                                                        <div id="collapseOne" className={cardPanel > 0?"collapse show":"collapse"}  role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" style={{}}>
                                                                            <div className="card-body">


{
    CardUrl &&

    <iframe scrolling="no" src={CardUrl} allowTransparency="true" allowFullScreen="true" style={{width: '100%'}} height={550} frameBorder="no" />

}





{
    !CardUrl  &&  <form className="forms-sample" onSubmit={process_card}  >

        <div className="email-compose-fields" data-select2-id={14}>


            <div className="description">
                <div className="form-group row py-0">
                    <label className="col-md-3 control-label">Name</label>
                    <div className="col-md-9">




                        <input
                            required={"required"}
                            className={" form-control "}
                            placeholder="Your name here"
                            value={Carddata.name}
                            onChange={(e)=>setCardData({...Carddata,name:e.target.value})}

                        />



                    </div>
                </div>
            </div>



            <div className="description mt-3">
                <div className="form-group row py-0">
                    <label className="col-md-3 control-label">Email</label>
                    <div className="col-md-9">




                        <input
                            type={"email"}
                            required={"required"}
                            className={" form-control "}
                            placeholder="Your email here"
                            value={Carddata.email}
                            onChange={(e)=>setCardData({...Carddata,email:e.target.value})}

                        />



                    </div>
                </div>
            </div>






            <div className="description mt-3">
                <div className="form-group row py-0">
                    <label className="col-md-3 control-label">Mobile</label>
                    <div className="col-md-9">




                        <NumberFormat
                            required={"required"}
                            className={" form-control "}
                            placeholder="Your Phone number e.g 0703956277"
                            value={Carddata.phone}
                            onChange={(e)=>setCardData({...Carddata,phone:e.target.value})}

                            format="#### ### ###" />



                    </div>
                </div>
            </div>







            <div className="description mt-3">
                <div className="form-group row py-0">
                    <label className="col-md-3 control-label">Amount</label>
                    <div className="col-md-9">




                        <NumberFormat
                            required={"required"}
                            className={" form-control "}
                            placeholder="Enter amount here"
                            value={Carddata.amount}
                            onChange={(e)=>setCardData({...Carddata,amount:e.target.value})}

                        />




                    </div>
                </div>
            </div>




            <div className="description mt-3">
                <div className="form-group row py-0">
                    <label className="col-md-3 control-label">Reason</label>
                    <div className="col-md-9">




                        <input
                            required={"required"}
                            className={" form-control "}
                            placeholder="Reason for payment"
                            value={Carddata.reason}
                            onChange={(e)=>setCardData({...Carddata,reason:e.target.value})}

                        />



                    </div>
                </div>
            </div>



        </div>


        <div className="form-group row pr-2 pl-2">

            <div className="col-md-2">
            </div>

            <div className="col-md-10">
                <div className="form-group mb-0">






                    {

                        !sending_card ?
                            <button type={"submit"} className="btn btn-success  btn-space btn-block">Send</button>

                            :
                            <button type={"button"} disabled={true} className="btn  btn-success btn-space btn-block">   Requesting....</button>

                    }



                </div>
            </div>


        </div>

    </form>

}



                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>





                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {/* middle wrapper end */}
                                        {/* right wrapper start */}
                                        <div className="d-none d-xl-block col-xl-3 right-wrapper">

                                        </div>
                                        {/* right wrapper end */}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                </div>
            }



        </div>
    )
}