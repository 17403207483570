import React from "react";
import utils from "../Utils";

export default ({change_state})=>{
    change_state('api');
    let mailto = 'mailto:'+utils.support_email


    return (  <div className="col-lg-12 stretch-card">
        <div className="card">
            <div className="card-body ">

                <div className="col-md-12 main-panel bg-white">
                    <div className="main-panel-wrapper">
                        <h4 className="mt-2 font-weight-light mb-4">NobleSMS Documentation (v1.0.0)</h4>
                        <p className>Thank you so much for choosing NobleSMS for your SMS broadcasting. If you have any questions that are beyond the scope of this documentation, please feel free to email to <a href={mailto} target="_blank">{utils.support_email}</a> :)</p>
                        <hr className="my-5" id="introduction" />
                        <div className="grid-margin">
                            <h4 className="mb-4">Introduction</h4>
                            <p>The aim of this API is to enable you send sms messages from your application to end
                                user mobile phones with ease and securely without necessarily logging into the
                                 web application.  It’s faster, safer and convenient! The API is written
                                based on REST principles.</p>
                        </div>
                        {/*<hr className="my-5" id="fileStructure" />*/}
                        {/*<div className="grid-margin">*/}
                            {/*<h4 className="mb-4">Base URL</h4>*/}
                            {/*<p>For secure communication between your application and the sms server, you are*/}
                                {/*encouraged to use the base URL with secure http (https).</p>*/}

                            {/*<code> {utils.base_url}</code>*/}
                        {/*</div>*/}


                        <hr className="my-5" id="fileStructure" />
                        <div className="grid-margin">
                            <h4 className="mb-4">Check Balance</h4>
                            <p>For secure communication between your application and the sms server, you are
                                encouraged to use the base URL with secure http (https).</p>

                            <code> {utils.base_url}api/balance</code>
                        </div>
                        <hr className="my-5" id="fileStructure" />

                        <div className="grid-margin">
                          <p className="mb-2">Make a POS / GET request witht the following parameters to retrieve your account balance</p>
                            <div className="table-responsive mt-4">
                                <table className="table table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Parameter</th>
                                        <th>Description</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td><code>username</code></td>
                                        <td>Your login username</td>
                                    </tr>
                                    <tr>
                                        <td><code>password</code></td>
                                        <td>Your login password.</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>



                        </div>






                        <hr className="my-5" id="fileStructure" />
                        <div className="grid-margin">
                            <h4 className="mb-4">Send Message</h4>
                            {/*<p>For secure communication between your application and the sms server, you are*/}
                                {/*encouraged to use the base URL with secure http (https).</p>*/}

                            <code> {utils.base_url}api/sendmessage</code>
                        </div>
                        <hr className="my-5" id="fileStructure" />

                        <div className="grid-margin">
                            <p className="mb-2">Make a POS / GET request witht the following parameters to send message with your account</p>
                            <div className="table-responsive mt-4">
                                <table className="table table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Command</th>
                                        <th>Description</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td><code>username (required and url-encoded UTF-
                                            8)</code></td>
                                        <td>Your login username</td>
                                    </tr>
                                    <tr>
                                        <td><code>password (required and url-encoded UTF-
                                            8)</code></td>
                                        <td>Your login password.</td>
                                    </tr>
                                    <tr>
                                        <td><code>to (required and
                                            url-encoded UTF-8)</code></td>
                                        <td>This refers to the number you are sending the message to. The
                                            format of the number must include the country code without
                                            the (+) sign. Example: 2547xxxxxxxx or 2567xxxxxxxx. To send
                                            more than one number, separate the numbers with a comma (,)
                                            and URL-encode the string of numbers.</td>
                                    </tr>
                                    <tr>
                                        <td><code>from (required and url-encoded UTF-
                                            8)</code></td>
                                        <td>This refers to the number that shows on the recipient’s mobile
                                            phone. The use of special characters is discouraged and the
                                            length is limited to 11 characters.
                                            If you desire to use special characters remember to URL encode
                                            them.</td>
                                    </tr>
                                    <tr>
                                        <td><code>message (required
                                            and url-encoded UTF-
                                            8) </code></td>
                                        <td>This is the actual message you wish to deliver to the recipient.
                                            The length of the message is currently limited to 1600
                                            characters.
                                            However, note that you will be charged for every 160 characters </td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>

                            <hr className="my-5" id="support" />
                            <h4 className="mb-2">Support</h4>
                            <p className="mb-5 pb-5">We are here to assist you, thank you for report any bug/issue to our email <a href="#">{utils.support_email}</a>.</p>



                        </div>


                    </div>
                </div>
            </div>
        </div>

    </div>);

}