
import React,{useRef, useState, useEffect, } from 'react';

import parse_url from '../Utils'

// import '../../static/dist/css/ace.min.css'
// import '../../static/dist/css/ace-themes.min.css'


import '../../assets/vendors/core/core.css'
import '../../assets/vendors/bootstrap-datepicker/bootstrap-datepicker.min.css'
import '../../assets/vendors/mdi/css/materialdesignicons.min.css'
// import '../assets/vendors/mdi/css/materialdesignicons.min.css'
import '../../assets/fonts/feather-font/css/iconfont.css'
import '../../assets/vendors/flag-icon-css/css/flag-icon.min.css'
import '../../assets/css/demo_1/style.css'
import '../../assets/vendors/dropzone/dropzone.min.css'
import '../../assets/vendors/dropify/dist/dropify.min.css'






import {ReactSession} from 'react-client-session';
import {Helmet} from "react-helmet";
import Modal from 'react-awesome-modal';
import {Link,HashRouter, BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Logout from "../Logout";
import Menu from "./Menu";
import Projects from "./Projects";
import Collections from "./Collections";
import Payouts from "./Payouts";

import Download_reports from "./Download_reports";
import NoProjects from "./Projects/NoProjects";
import CreateProject from "./Projects/CreateProject";
import ListProjects from "./Projects/ListProjects";
import EditProject from "./Projects/EditProject";
import Withdraw_money from "./Withdraw_money";
import Change_password from "./Change_password";
import CreateUser from "./Users/CreateUser";
import Users from "./Users/Users";
import EditUser from "./Users/EditUser";
import UserProfile from "./Users/UserProfile";

import utils from "../Utils";
import SideMenu from "./SideMenuUser";
import NetworkContainer from "./Networks/NetworkContainer";
import RoutesContainer from "./MessagingRoutes/RoutesContainer";
import UserContainer from "./Users/UserContainer";
import DepositFunds from "./Transactions/DepositFunds";
import TransactionHistory from "./Transactions/TransactionHistory";
import ContactsContainer from "./Contacts/ContactsContainer";
import SendMessage from "./UserMessaging/SendMessage";
import PersonalizedMessages from "./Messaging/PersonalizedMessages";
import ScheduledMessages from "./UserMessaging/ScheduledMessages";
import SentMessage from "./UserMessaging/SentMessage";
import ApiDocument from "./ApiDocument";
import SentMessageDetails from "./UserMessaging/SentMessageDetails";
import InitiatePersonalized from "./UserMessaging/InitiatePersonalized";
import Dashboard from "./Dashboard/Dashboard";
import DashboardUser from "./Dashboard/DashboardUser";

const Customer = ()=>{

    // document.title = "Lipa";
    const[displayLogoutModal, setdisplayLogoutModal] = useState(false);
    const [link,setLink] = useState([]);
    const [sub_link,setSubLink] = useState([]);
    const [Data,setData] = useState([]);




    const getAccountBalance=()=>{


        {

            {


                fetch(utils.base_url + 'dashboard/user_details/'+ReactSession.get('id'),
                    {
                        method: 'POST',

                        headers: {'Content-Type': 'application/json'},

                    }).then(res => res.json())
                    .then(json => {







                        if(json)
                        {






                            setData(json);






                        }

                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }


    useEffect(()=>{


        setInterval(()=>{

            getAccountBalance()
        },5000)
        //


    },[]);






    const change_state=(new_link)=>{
         setLink(new_link)

        return [new_link];


    }

    const change_sublink=(new_link)=>{
         setSubLink(new_link)

        return [new_link];


    }


    document.title = "NobleSMS | "+link;


    return (

        <div>
            <HashRouter>

            <div className="main-wrapper">




                {
                    <SideMenu link={link} sub_link={sub_link}/>
                }




                <div className="page-wrapper">

                    <nav className="navbar">
                        <a href="#" className="sidebar-toggler">
                            <i data-feather="menu" />
                        </a>
                        <div className="navbar-content">

                            <ul className="navbar-nav">


                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to={"transactions/deposit_funds"} id="languageDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       Credits : <span className=" m-2 p-2 text-primary"><strong>{Data.account_balance && parseFloat(Data.account_balance).toLocaleString()}</strong>/-</span>
                                    </Link>

                                </li>




                                <li className="nav-item dropdown nav-profile">
                                    <a className="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {ReactSession.get('firstname')}   {ReactSession.get('lastname')}    <img src={utils.base_url+ReactSession.get('picture_url')} alt="profile" />
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="profileDropdown">
                                        <div className="dropdown-header d-flex flex-column align-items-center">

                                            <div className="info text-center">
                                                {/*<p className="name font-weight-bold mb-0">Amiah Burton</p>*/}
                                                <p className="email text-muted mb-3">{ReactSession.get('email')}</p>
                                            </div>
                                        </div>
                                        <div className="dropdown-body">
                                            <ul className="profile-nav p-0 pt-3">


                                                <li className="nav-item">
                                                    <Link to={"/change_password"} className="nav-link">
                                                        <i data-feather="edit" />
                                                        <span>Edit Password</span>
                                                    </Link>

                                                </li>

                                                <li className="nav-item"   onClick={()=>setdisplayLogoutModal(true)}>
                                                    <a href="javascript:;" className="nav-link">
                                                        <i data-feather="log-out" />
                                                        <span>Log Out</span>
                                                    </a>
                                                </li>


                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    {/* partial */}
                    <div className="page-content">


                        <Switch>

                            <Route exact path='/'>
                                <DashboardUser change_state={change_state}/>
                            </Route>

                            <Route exact path='/logout'>
                                <Logout/>
                            </Route>


                            <Route exact path='/change_password'>
                                <Change_password change_state={change_state} sublink={change_sublink}/>
                            </Route>

                            <Route exact path='/mobile_networks/:action'>
                                <NetworkContainer change_state={change_state} change_sublink={change_sublink}/>
                            </Route>

                            <Route exact path='/messaging_routes/:action'>
                                <RoutesContainer change_state={change_state} change_sublink={change_sublink}/>
                            </Route>

                            <Route exact path='/user_accounts/:action'>
                                <UserContainer change_state={change_state} change_sublink={change_sublink}/>
                            </Route>

                            <Route exact path='/transactions/deposit_funds'>
                                <DepositFunds change_state={change_state} change_sublink={change_sublink}/>
                            </Route>


                            <Route exact path='/transactions/transaction_history'>
                                <TransactionHistory change_state={change_state} change_sublink={change_sublink}/>
                            </Route>




                            <Route exact path='/contact_list/:action'>
                                <ContactsContainer change_state={change_state} change_sublink={change_sublink}/>
                            </Route>



                            <Route exact path='/send_message'>
                                <SendMessage change_state={change_state} change_sublink={change_sublink}/>
                            </Route>


                            {/*<Route exact path='/personalized_messages'>*/}
                                {/*<PersonalizedMessages change_state={change_state} change_sublink={change_sublink}/>*/}
                            {/*</Route>*/}


                            <Route exact path='/scheduled_messages'>
                                <ScheduledMessages change_state={change_state} change_sublink={change_sublink}/>
                            </Route>

                            <Route exact path='/sent_messages'>
                                <SentMessage change_state={change_state} change_sublink={change_sublink}/>
                            </Route>
                            <Route exact path='/api'>
                                <ApiDocument change_state={change_state} change_sublink={change_sublink}/>
                            </Route>
                            <Route exact path='/message_details/:message_id'>
                                <SentMessageDetails change_state={change_state} change_sublink={change_sublink}/>
                            </Route>

                            <Route exact path='/personalized_messages'>
                                <InitiatePersonalized change_state={change_state} change_sublink={change_sublink}/>
                            </Route>


                            {/*<Route exact path='/scheduled_messages'>*/}
                                {/*<SendMessage change_state={change_state} change_sublink={change_sublink}/>*/}
                            {/*</Route>*/}


                            {/*<Route exact path='/sent_messages'>*/}
                                {/*<SendMessage change_state={change_state} change_sublink={change_sublink}/>*/}
                            {/*</Route>*/}






                        </Switch>



                    </div>




                    <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <p className="text-muted text-center text-md-left">Copyright © {parse_url.current_year} <a href="#" target="_blank">NobleSMS </a>is a product of Momentum Systems . All rights reserved</p>
                        <p className="text-muted text-center text-md-left mb-0 d-none d-md-block">Handcrafted With <i className="mb-1 text-primary ml-1 icon-small" data-feather="heart" /></p>
                    </footer>

                    {/* partial */}
                </div>
            </div>


            <Helmet>

                <script src="../public_assets/vendors/core/core.js"/>

                <script src="../public_assets/vendors/chartjs/Chart.min.js"/>
                <script src="../public_assets/vendors/jquery.flot/jquery.flot.js"/>
                <script src="../public_assets/vendors/jquery.flot/jquery.flot.resize.js"/>
                <script src="../public_assets/vendors/bootstrap-datepicker/bootstrap-datepicker.min.js"/>
                <script src="../public_assets/vendors/apexcharts/apexcharts.min.js"/>
                <script src="../public_assets/vendors/progressbar.js/progressbar.min.js"/>


                <script src="../public_assets/vendors/select2/select2.min.js"/>
                <script src="../public_assets/vendors/simplemde/simplemde.min.js"/>
                <script src="../public_assets/vendors/feather-icons/feather.min.js"/>

                <script src="../public_assets/vendors/feather-icons/feather.min.js" type="text/javascript"/>
                <script src="../public_assets/js/template.js"/>

                <script src="../public_assets/js/dashboard.js"/>
                <script src="../public_assets/js/datepicker.js" type="text/javascript"/>




            </Helmet>


            <Modal

                fullscreen={true}

                visible={displayLogoutModal}

                show={displayLogoutModal}

                style={{
                    minWidth:'500px;'
                }}


                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
                id="profile-modal"

                className="modal"
            >



                <div className="modal-dialog" role="document">
                    <div className="modal-content">

                        <div className="modal-body p-5 text-center">
                            <p >Thank you so much for using Noble SMS.<br/> Do you want to logOut? </p>
                        </div>
                        <div className="modal-footer pb-0 mb-0">
                            <button type="button" className="btn btn-danger"
                                    onClick={()=>setdisplayLogoutModal(false)}
                                    data-dismiss="modal">No</button>


                            <Link to={"/logout"}>
                                <div className="btn btn-success">
                                    Yes
                                </div>
                            </Link>


                        </div>
                    </div>
                </div>




            </Modal>

            </HashRouter>

        </div>




    )

}




export default Customer;