import {ReactSession} from "react-client-session";

const utils =  {
         support_email:'info@noblesms.ug',
         // base_url:'http://api.noblesms.ug/',
         base_url:'http://api.noblesms.ug/',
      //    base_url:'http://localhost:3000/',
         picture_url:'http://api.noblesms.ug/',
     //     picture_url:'http://localhost/lipa/',
      //   base_url:'https://maxtrace.app/bizybus/index.php/merchant_api/',
        current_year:new Date().getFullYear(),

          getTimestring:(timestamp)=>{
        var date = new Date(timestamp*1000);

                var fulltime = date.toLocaleDateString()+" "+ date.toLocaleTimeString()
        // return  date.toISOString();
        return fulltime;
            },
    getTimestringShort:(timestamp)=>{
        var date = new Date(timestamp*1000);

        var fulltime = date.toLocaleDateString()
        // return  date.toISOString();
        return fulltime;
    },



    };




export default  utils;