import {useEffect, useState} from "react";
import utils from "../../Utils";
import LineCharts from '../LineChart'

import Highcharts from 'highcharts/highstock';
import {Bar} from "react-chartjs-2";
import {Link} from "react-router-dom";
import urlencode from "urlencode";
import React from "react";
export default ({change_state})=>{

    change_state('dashboard');

    const [totalNewCollections,setTotalNewCollections]=useState({})
    const [totalNewMessages,setTotalNewMessages]=useState({})
    const [totalNewUsers,setTotalNewUsers]=useState({})
    const [totalUsers,setTotalUsers]=useState({})
    const [totalPending,setTotalPending]=useState({})
    const [totalBalance,setTotalBalance]=useState({})
    const [Data,setData]=useState([])
    const [Traffic,setTraffic]= useState({});




    const scroll={

        overflowY: 'scroll',

        width:'100%',

        height:'800px',


    };



    const getTxstatus=(status)=>{

//        alert(status);

        switch (status) {

            case 0:
                return 'email-list-item email-list-item--unread'
                break;
            case 1:
                return 'email-list-item'
                break;


        }

    }


    const recentMessages=()=>{


        {


            {


                fetch(utils.base_url + 'dashboard/list_all_messages/',
                    {
                        method: 'POST',

                        headers: {'Content-Type': 'application/json'},

                    }).then(res => res.json())
                    .then(json => {




                        if(json){

                            let newData = Data

                            json.map(result=> {

                                newData = [...newData, result]

                            })


                            setData(newData);






                        }

                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }




    const total_NewMessages=()=>{




        fetch(utils.base_url + 'dashboard/new_messages',
            {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(json => {







                if(json)
                {




                    console.log(json)


                    setTotalNewMessages(json);





                }

            })
            .catch((e) => {

                console.log(e);

            })



    }

    const total_NewCollections=()=>{




        fetch(utils.base_url + 'dashboard/new_collections',
            {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(json => {







                if(json)
                {




                    console.log(json)


                    setTotalNewCollections(json);





                }

            })
            .catch((e) => {

                console.log(e);

            })



    }



    const sms_traffic=()=>{





        fetch(utils.base_url + 'dashboard/sms_traffic',
            {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(json => {







                if(json) {


                    setTraffic({

                        labels: json.labels,

                        datasets: [
                            {
                                height:50,
                                label: 'Messages',
                                data: json.data,
                                fill: false,
                                // backgroundColor: 'rgb(255, 99, 132)',
                                // backgroundColor: 'rgba(82,196,26,1)',
                                backgroundColor: 'rgba(114,124,245,1)',
                                // borderColor: 'rgba(255, 99, 132, 0.2)',
                                borderColor: 'rgba(202,245,181,1)',
                            },
                        ],

                    })

                }


                })
            .catch((e) => {

                console.log(e);

            })


    }

    const total_users=()=>{




        fetch(utils.base_url + 'dashboard/total_users',
            {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(json => {







                if(json)
                {




                    console.log(json)


                    setTotalUsers(json);





                }

            })
            .catch((e) => {

                console.log(e);

            })



    }

    const total_Newusers=()=>{




        fetch(utils.base_url + 'dashboard/new_users',
            {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(json => {







                if(json)
                {




                    console.log(json)


                    setTotalNewUsers(json);





                }

            })
            .catch((e) => {

                console.log(e);

            })



    }


    const total_pending=()=>{




        fetch(utils.base_url + 'dashboard/total_pending',
            {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(json => {







                if(json)
                {







                    // setData([...data, json]);


                    setTotalPending(json);


                }

            })
            .catch((e) => {

                console.log(e);

            })



    }

    const total_balance=()=>{




        fetch(utils.base_url + 'dashboard/total_account_balance',
            {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(json => {







                if(json)
                {







                    // setData([...data, json]);


                    setTotalBalance(json);


                }

            })
            .catch((e) => {

                console.log(e);

            })



    }




    useEffect(()=>{
        total_users();total_pending();total_balance();sms_traffic();total_Newusers();total_NewMessages();total_NewCollections();recentMessages();


        setInterval(()=>{

            total_users();total_pending();total_balance();sms_traffic();total_Newusers();total_NewMessages();total_NewCollections();recentMessages();

        },10000)


    },[]);





    return (



        <div >
            <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
                <div>
                    <h4 className="mb-3 mb-md-0">Welcome to Dashboard</h4>
                </div>

            </div>
            <div className="row">
                <div className="col-12 col-xl-12 stretch-card">
                    <div className="row flex-grow">
                        <div className="col-md-4 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <h6 className="card-title mb-0">Total Users</h6>

                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-12 col-xl-5">
                                            <h3 className="mb-2">{totalUsers.total_users}</h3>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <h6 className="card-title mb-0">Pending Messages</h6>

                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-12 ">
                                            <h3 className="mb-2">{totalPending.total_pending && parseFloat(totalPending.total_pending).toLocaleString()}</h3>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <h6 className="card-title mb-0">Total Account Balance</h6>

                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-12 ">
                                            <h3 className="mb-2">{totalBalance.total_account_balance && parseFloat(totalBalance.total_account_balance).toLocaleString()}</h3>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div> {/* row */}
            <div className="row">
                <div className="col-12 col-xl-8 grid-margin stretch-card" >
                    <div className="card overflow-hidden">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-baseline mb-4 mb-md-3">
                                <h6 className="card-title mb-0">SMS traffic (This month)</h6>

                            </div>

                            <div className="flot-wrapper mt-1"  >


                                {
                                    // Traffic && <LineCharts height={50} data={Traffic}/>
                                    Traffic && <Bar  data={Traffic} width={100}
                                                    height={300}
                                                    options={{ maintainAspectRatio: false }} />
                                }




                                {/*<div id="flotChart1" className="flot-chart" />*/}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-md-4">

                <div className=" grid-margin stretch-card">
                    <div className="card overflow-hidden">
                        <div className="card-body">
                            <div className="d-flex ">
                                <h6 className="card-title mb-0">Collections Today</h6>

                            </div>
                            <div className="row">
                                <div className="col-6 col-md-12 ">
                                    <h3 className="mb-2">{totalNewCollections.new_collections? parseFloat(totalNewCollections.new_collections).toLocaleString():'0'}</h3>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div className=" grid-margin stretch-card">
                    <div className="card overflow-hidden">
                        <div className="card-body">
                            <div className="d-flex ">
                                <h6 className="card-title mb-0">New Messages</h6>

                            </div>
                            <div className="row">
                                <div className="col-6 col-md-12 col-xl-5">
                                    <h3 className="mb-2">{totalNewMessages.new_messages && parseFloat(totalNewMessages.new_messages).toLocaleString()}</h3>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                <div className=" grid-margin stretch-card">
                    <div className="card overflow-hidden">
                        <div className="card-body">
                            <div className="d-flex ">
                                <h6 className="card-title mb-0">New Users</h6>

                            </div>
                            <div className="row">
                                <div className="col-6 col-md-12 col-xl-5">
                                    <h3 className="mb-2">{totalNewUsers.new_users}</h3>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>



                </div>


            </div> {/* row */}




            <div className={"row pl-2 pr-2"}>

                <div className="col-md-12  card " >


                    <div className=" inbox-wrapper ">
                        <div >
                            <div >
                                <div >
                                    <div >

                                        <div className="col-lg-12 email-content">
                                            <div className="email-inbox-header">
                                                <div className="row ">
                                                    <div className="col-lg-12">
                                                        <div className="email-title m-2 ">

                                                            Recent Sent Messages
                                                            {/*<span className="new-messages">(2 new messages)</span>*/}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="email-list" >





                                                {
                                                    Data && Data.map(data=>(




                                                        <div className={getTxstatus(parseInt(data.status))}>




                                                            <Link to={"/message_details/"+data.messageout.id+"?details="+urlencode(JSON.stringify(data.messageout))+"&user="+urlencode(data.user.firstname+" "+data.user.lastname)} className="email-list-detail">
                                                                <div>
                                                                    <span className="from">{data.messageout.senderid}</span>
                                                                    <p className="msg"> {data.messageout.message}</p>
                                                                    <p className="msg">  <span className="icon">
                          <i className={"mdi mdi-account-outline mdi-18px"}></i>
                      </span> {data.user.firstname} {data.user.lastname}</p>
                                                                    <p className="msg">
                                                                        <i
                                                                            className="mdi mdi-arrow-bottom-right"></i> {utils.getTimestring(data.created_at)}</p>
                                                                    <p className="msg"><i className="mdi mdi-alert-circle"></i> {data.counts}  ({data.sms_cost})UGX</p>

                                                                </div>
                                                                <span className="date">
                      <span className="icon">
                          <i className={"mdi mdi-clock mdi-16px"}></i>
                      </span>
                                                                    {
                                                                        utils.getTimestring(data.messageout.schedule_date)

                                                                    }
                    </span>
                                                            </Link>
                                                        </div>



                                                    ))
                                                }













                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> {/* row */}
            </div>

        </div>

    )


}