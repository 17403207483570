import React, {useEffect, useState} from "react";
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import {Link} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
var urlencode = require('urlencode');
export default  ()=>{

    const [Search,setSearch] = useState([])
    const [Data,setData] = useState([])
    const [page,setpage] = useState(0)


    let value = true;
    let isBottomValue = true;

    let reload = true;


    const submitAlert=(id) => confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                    <h1>Are you sure?</h1>
                    <p>Do you want to delete this User Account?</p>

                    <div className="modal-footer">
                        <button className={'btn btn-primary btn-sm'} onClick={onClose}>Ignore</button>
                        <button
                            className={'btn btn-success btn-sm'}
                            onClick={() => {

                                setData(results =>results.filter(data => data.id !== id));
                                confirm_delete(id);
                                onClose();



                            }}
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            );
        }
    });




    const handleDelete=(id)=>{

        // setnew_data(newData);

        submitAlert(id)

        console.log(id);
    }





    const confirm_delete=(id)=>{


        fetch(utils.base_url + 'user/delete_user/'+id+'/'+ReactSession.get('id'),
            {
                method: 'POST',

                headers: {'Content-Type': 'application/json'},

            }).then(res => res.json())
            .then(json => {


                reload = false;
              //  load_data(page)


            })
            .catch((e) => {

                console.log(e);

            })


    }



    const load_data=()=>{


        {

            isBottomValue = false;
            {


                fetch(utils.base_url + 'user/users/'+ReactSession.get('id')+"/0",
                    {
                        method: 'POST',
                        body: JSON.stringify({ Search }),
                        headers: {'Content-Type': 'application/json'},


                    }).then(res => res.json())
                    .then(json => {




                        if(json.length > 0)
                        {
                            setData(json);
                        }else{
                            setData([]);
                        }




                        if(json.length > 0 && reload)
                        {

                            isBottomValue = true;
                            value = false;



                            let newData = Data

                            json.map(result=> {

                                newData = [...newData, result]

                            })


                            // setData(newData);







                        }

                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }


    const scroll={

        overflowY: 'scroll',

        width:'100%',

        height:'800px',



    };
    const FontSize={


        fontSize:12

    };



    const handleScroll=(event)=>{


        const target = event.target;

        console.log(target);

        if(target.scrollHeight - target.scrollTop == target.clientHeight)
        {

            setpage(page+1);


        }




    }

    ReactSession.set("page",0);

    useEffect(()=>{


        load_data(page)


    },[Search]);


    const getTxstatus=(status)=>{

//        alert(status);

        switch (status) {

            case 1:
               return (<i className={"mdi mdi-clock text-warning"}>Pending</i>)
                break;
            case 0:
                return (<span className={"mdi mdi-check text-success"}>Success</span>)
                break;


        }

    }


    return (
      <div>
          <div className="col-lg-12 stretch-card">
              <div className="col-lg-12">
                  <div >



                      {/*<div className="email-head">*/}
                          {/*<div className="email-head-title d-flex align-items-center">*/}
                              {/*Users*/}
                          {/*</div>*/}
                      {/*</div>*/}


                      <div className="form-group mt-3">
                          {/*<label htmlFor="exampleInputEmail1">First name</label>*/}
                          <input name={"create-firstname"} type="text"  required={"required"}  onChange={

                              (e)=> setSearch({...Search,search:e.target.value})

                          } className="form-control" placeholder="Search User" />
                      </div>



                      <div className="table-responsive mt-3" onScroll={handleScroll} style={scroll}>
                          <table className="table table-hover mb-0">
                              <thead>
                              <tr>

                                  <th className="pt-0">DateTime</th>
                                  <th className="pt-0">Name</th>



                                  <th className="pt-0">Email</th>
                                  <th className="pt-0">Balance</th>

                                  <th className="pt-0"></th>
                              </tr>
                              </thead>
                              <tbody>

                              {
                                  Data && Data.map(data=>(




                                      <tr >



                                          <td>{utils.getTimestring(data.created_at)}</td>
                                          <td>{data.firstname} {data.lastname}</td>


                                          <td><Link to={"/user_accounts/top_up/?details="+urlencode(JSON.stringify(data))}>
                                              {data.email}
                                          </Link>
                                              </td>


                                          <td>{parseFloat(data.account_balance).toLocaleString()}</td>




                                          <td>

                                              <i className={"mdi mdi-delete mdi-18px m-2 text-danger"} style={{cursor:'pointer'}} onClick={()=>handleDelete(data.id)}></i>

                                              <Link to={"/user_accounts/edit_details/?details="+urlencode(JSON.stringify(data))}>
                                                  <i className={"mdi mdi-pencil mdi-18px"} style={{cursor:'pointer'}}></i>
                                              </Link></td>

                                      </tr>




                                  ))
                              }

                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>


      </div>
    );
}