import {HashRouter, Link} from "react-router-dom";

import '../../../assets/vendors/select2/select2.min.css'
import '../../../assets/vendors/simplemde/simplemde.min.css'
import {Helmet} from "react-helmet";
import React,{useState, useEffect} from "react";
import {Input} from "react-input";
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import Alert from "../../Alert";
import PasswordStrengthBar from "react-password-strength-bar";

export default ()=>{


    const [login, setLogin] = useState(true);
    const [passwordscore, setPasswordscore] = useState(0);
    const [creating,setCreating] = useState(false);
    const [create_account, setCreateAccount] = useState([]);
    const [data,setData] = useState({});
    const [sending,setSending] = useState(false);
    const [error,setError] = useState([]);

    // sublink('create_users')
    // change_state('users')



    const createAccount = e => {
        e.preventDefault()


        setSending(true);
        setCreating(true);


        fetch(utils.base_url+'user/create_user/'+ReactSession.get('id'), {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ create_account }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {

                setSending(false);
                setCreating(false);


                if(json.id)
                {


                    setError({
                        icon:'info',
                        header:'Thank you',
                        class_Name:'success',
                        status_create_account:true,
                        status:true,
                        message:'Account created successfully. Thank you!',
                    });


                    e.target.reset();



                }else{


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_create_account:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                }


                console.log(json.ResultCode);
                console.log(json.ResultMessege);

                //setUser(json.user)

            })
            .catch((e)=>{
                setCreating(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    console.log(     ReactSession.get("username"));



    const getScore=(e)=>{
        setPasswordscore(e);
        console.log(e)   ;
    }


    const handleStatus=(status)=>{

        setError({...error,status_login:status});

    }


    const handleStatusCreate=(status)=>{

        setError({...error,status_create_account:status});

    }




    useEffect(()=>{

        console.log(data);

    },[data])

    return (

        <div className="row inbox-wrapper" data-select2-id={20}>
            <div className="col-lg-12" data-select2-id={19}>
                <div >
                    <div >
                        <div className="row" data-select2-id={16}>

                            <div className="col-lg-12 email-content" data-select2-id={15}>




                                {
                                    error.status_create_account && <Alert message={error.message} icon={error.icon} class_Name={error.class_Name} header={error.header}  showing={error.status_create_account}  handleStatus={handleStatusCreate} />
                                }


                                <form className="forms-sample"  onSubmit={createAccount}>


                                    <div className="email-compose-fields" data-select2-id={14}>



                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">First name</label>
                                            <input name={"create-firstname"} type="text"  onChange={(e)=> setCreateAccount({...create_account,first_name:e.target.value})} required={"required"} className="form-control" placeholder="First name" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Last name</label>
                                            <input name={"create-last"} type="text"  onChange={(e)=> setCreateAccount({...create_account,last_name:e.target.value})} required={"required"} className="form-control" placeholder="Last name" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Email address</label>
                                            <input name={"create-email"} type="email"  onChange={(e)=> setCreateAccount({...create_account,email:e.target.value})} required={"required"} className="form-control" placeholder="Email" />
                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Password</label>
                                            <input name={"create-password"} type="password" onChange={(e)=> setCreateAccount({...create_account,password:e.target.value})} required={"required"} className="form-control"   placeholder="Password" />
                                            <PasswordStrengthBar password={create_account.password} />

                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Confirm Password</label>
                                            <input type="password"  onChange={(e)=> setCreateAccount({...create_account,confirm_password:e.target.value})} required={"required"} className="form-control" id="exampleInputPassword1"  placeholder="Confirm Password" />
                                            <PasswordStrengthBar password={create_account.confirm_password} onChangeScore={getScore}/>

                                        </div>





                                        <div className="mt-3">


                                            {

                                                passwordscore >= 2 && create_account.confirm_password === create_account.password && create_account.password.length > 0 ?

                                                    !creating ?
                                                        <button type={"submit"} className="btn btn-block btn-success mr-2 mb-2 mb-md-0 ">Sign up</button>

                                                        :
                                                        <button type={"button"} disabled={true} className="btn btn-block btn-success mr-2 mb-2 mb-md-0 text-white">   Authorizing....</button>

                                                    :
                                                    <>
                                                    </>

                                            }




                                        </div>


                                    </div>

                                </form>





                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Helmet>

                {/*<script src="../public_assets/vendors/core/core.js"/>*/}

                {/*<script src="../public_assets/vendors/chartjs/Chart.min.js"/>*/}
                {/*<script src="../public_assets/vendors/jquery.flot/jquery.flot.js"/>*/}
                {/*<script src="../public_assets/vendors/jquery.flot/jquery.flot.resize.js"/>*/}
                {/*<script src="../public_assets/vendors/bootstrap-datepicker/bootstrap-datepicker.min.js"/>*/}
                {/*<script src="../public_assets/vendors/apexcharts/apexcharts.min.js"/>*/}
                {/*<script src="../public_assets/vendors/progressbar.js/progressbar.min.js"/>*/}


                {/*<script src="../public_assets/vendors/select2/select2.min.js"/>*/}
                {/*<script src="../public_assets/vendors/simplemde/simplemde.min.js"/>*/}
                {/*<script src="../public_assets/vendors/feather-icons/feather.min.js"/>*/}

                {/*<script src="../public_assets/vendors/feather-icons/feather.min.js" type="text/javascript"/>*/}
                {/*<script src="../public_assets/js/template.js"/>*/}

                {/*<script src="../public_assets/js/dashboard.js"/>*/}
                {/*<script src="../public_assets/js/datepicker.js" type="text/javascript"/>*/}




            </Helmet>
            <Helmet>

                {/*<script src="../../public_assets/vendors/core/core.js"/>*/}

                {/*<script src="../../public_assets/vendors/select2/select2.min.js"/>*/}
                {/*<script src="../../public_assets/vendors/simplemde/simplemde.min.js"/>*/}
                {/*<script src="../../public_assets/vendors/feather-icons/feather.min.js"/>*/}
                {/*<script src="../../public_assets/js/template.js"/>*/}
                {/*<script src="../public_assets/js/email.js"/>*/}



            </Helmet>

        </div>


    );
}