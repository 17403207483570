import {Link} from "react-router-dom";
import React from "react";
import {ReactSession} from "react-client-session";
export default ({link, sub_link})=>{

    return (
        <nav className="sidebar">
            <div className="sidebar-header">
                <a href="#" className="sidebar-brand">
                    Noble<span>SMS</span>
                </a>
                <div className="sidebar-toggler not-active">
                    <span />
                    <span />
                    <span />
                </div>
            </div>


            <div className="sidebar-body">
                <ul className="nav">
                    <li className="nav-item nav-category">Main</li>


                    <li className={link === 'dashboard'?'nav-item active':'nav-item'}>

                        <Link to={"/"} className={"nav-link"} >
                            <i className="link-icon" data-feather="box" />
                            <span className="link-title">Dashboard</span>
                        </Link>

                    </li>


                    {/*<li className="nav-item">*/}
                        {/**/}
                        {/**/}
                        {/*<a href="dashboard-one.html" className="nav-link">*/}
                            {/*<i className="link-icon" data-feather="box" />*/}
                            {/*<span className="link-title">Dashboard</span>*/}
                        {/*</a>*/}
                    {/*</li>*/}



                    <li className="nav-item nav-category">Messaging</li>


                    <li className={link === 'send_message'?'nav-item active':'nav-item'}>

                        <Link to={"/send_message"} className={"nav-link"} >
                            <i className="link-icon" data-feather="mail" />
                            <span className="link-title">Send Message</span>
                        </Link>

                    </li>


                    <li className={link === 'personalized_messages'?'nav-item active':'nav-item'}>

                        <Link to={"/personalized_messages"} className={"nav-link"} >
                            <i className="link-icon" data-feather="message-square" />
                            <span className="link-title">Personalized Messages</span>
                        </Link>

                    </li>

                    <li className={link === 'scheduled_messages'?'nav-item active':'nav-item'}>

                        <Link to={"/scheduled_messages"} className={"nav-link"} >
                            <i className="link-icon" data-feather="calendar" />
                            <span className="link-title">Scheduled messages</span>
                        </Link>

                    </li>


                    <li className={link === 'sent_messages'?'nav-item active':'nav-item'}>

                        <Link to={"/sent_messages"} className={"nav-link"} >
                            <i className="link-icon" data-feather="list" />
                            <span className="link-title">Sent messages</span>
                        </Link>

                    </li>





                    <li className="nav-item nav-category">Transactions</li>


                    <li className={link === 'deposit_funds'?'nav-item active':'nav-item'}>

                        <Link to={"/transactions/deposit_funds"} className={"nav-link"} >
                            <i className="link-icon" data-feather="send" />
                            <span className="link-title">Deposit funds</span>
                        </Link>

                    </li>


                    <li className={link === 'transaction_history'?'nav-item active':'nav-item'}>

                        <Link to={"/transactions/transaction_history"} className={"nav-link"} >
                            <i className="link-icon" data-feather="layers" />
                            <span className="link-title">Transaction history</span>
                        </Link>

                    </li>


                    <li className="nav-item nav-category">Tools</li>


                    <li className={link === 'contact_list'?'nav-item active':'nav-item'}>

                        <Link to={"/contact_list/list_contacts"} className={"nav-link"} >
                            <i className="link-icon" data-feather="book" />
                            <span className="link-title">Contact lists</span>
                        </Link>

                    </li>





                    <li className="nav-item nav-category">Docs</li>


                    <li className={link === 'api'?'nav-item active':'nav-item'}>

                        <Link to={"/api"} className={"nav-link"} >
                            <i className="link-icon" data-feather="hash" />
                            <span className="link-title">API </span>
                        </Link>

                    </li>



                </ul>
            </div>
        </nav>
    );

}