import {HashRouter, Link} from "react-router-dom";

import '../../../assets/vendors/select2/select2.min.css'
import '../../../assets/vendors/simplemde/simplemde.min.css'
import {Helmet} from "react-helmet";
import React,{useState, useEffect} from "react";
import {Input} from "react-input";
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import Alert from "../../Alert";
import PasswordStrengthBar from "react-password-strength-bar";
import Select from "react-select";
import Swal from "sweetalert2";

export default ({change_state, change_sublink})=>{




    change_state('personalized_messages');
    change_sublink('list_contacts');
    const [listName, setListName] = useState(true);
    const [passwordscore, setPasswordscore] = useState(0);
    const [creating,setCreating] = useState(false);
    const [create_account, setCreateAccount] = useState([]);
    const [data,setData] = useState({});
    const [Results,setResults] = useState({});
    const [sending,setSending] = useState(false);
    const [FileUploaded,setFileUploaded] = useState(false);
    const [error,setError] = useState([]);



    // const [routes,setRoutes] = useState([]);
    const [ListId,setListId] = useState([]);
    const [Search,setSearch] = useState([])



    const submits = e => {
        e.preventDefault()


        console.log(JSON.stringify({ data }));



        setSending(true);


        fetch(utils.base_url+'messaging/send_user_customized/'+ReactSession.get('id'), {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setSending(false);

                // history.push("/mobile_networks/list_networks");
                if(parseInt(json.ResultCode) === 10001)
                {




                    Swal.fire(
                        {
                            title: json.ResultMessage,
                            // text: json.Msisdn,
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton:false,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            // confirmButtonText: 'Proceed',
                            // cancelButtonText: 'Proceed',
                            // allowOutsideClick: false,


                        })



                    setError({
                        icon:'bell',
                        header:'Thank you',
                        class_Name:'success',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });

                   //  e.target.reset();
                   // setData({})





                }else{


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });

                }




            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };









    const handleStatus=(status)=>{

        setError({...error,status_login:status});

    }


    // sublink('create_users')
    // change_state('users')








    const handleStatusCreate=(status)=>{

        setError({...error,status_create_account:status});

    }

    const changeHandler = (event) => {

        setSelectedFile(event.target.files[0]);

        setIsSelected(true);

    };

    const [IsSelected, setIsSelected] = useState();
    const [selectedFile, setSelectedFile] = useState();

    const [isFilePicked, setIsFilePicked] = useState(false);



    const handleSubmission = (e) => {

        e.preventDefault()

        const formData = new FormData();

        setCreating(true);
        formData.append('File', selectedFile);
        formData.append('list_name', JSON.stringify(listName));



        fetch(

            utils.base_url+"api/uploadPersonalizedForm/"+ReactSession.get('id'),

            {

                method: 'POST',

                body: formData,

            }

        )

            .then((response) => response.json())

            .then((result) => {

                setCreating(false);
                setIsSelected(false);



                console.log(result)



                setResults(result)
                setFileUploaded(true)

                setData({from:result.default_number,to:result.default_number,fileName:result.fileName})



                // setError({
                //     icon:'info',
                //     header:'Thank you',
                //     class_Name:'success',
                //     status_create_account:true,
                //     status:true,
                //     message:'Contact list uploaded successfully. Thank you!',
                // });


               // e.target.reset();

                console.log('Success:', result);

            })

            .catch((error) => {

                console.error('Error:', error);

            });

    };



    useEffect(()=>{

        console.log(data);
        // load_routes()


    },[])

    return (

        <div className="inbox-wrapper">
            <div >
                <div className="card">
                    <div className="card-body">



                        <div className="email-head">
                            <div className="email-head-title d-flex align-items-center">

                              Personalized messages



                            </div>
                        </div>




                        {

                            !FileUploaded && <div className="col-lg-12 email-content">


                                <div className="email-list">
                                    <div className="row inbox-wrapper" data-select2-id={20}>
                                        <div className="col-lg-12" data-select2-id={19}>
                                            <div >
                                                <div >
                                                    <div className="row" data-select2-id={16}>

                                                        <div className="col-lg-12 email-content" data-select2-id={15}>




                                                            {/*{*/}
                                                                {/*error.status_create_account && <Alert message={error.message} icon={error.icon} class_Name={error.class_Name} header={error.header}  showing={error.status_create_account}  handleStatus={handleStatusCreate} />*/}
                                                            {/*}*/}


                                                            <form className="forms-sample" encType={"multipart/form-data"} onSubmit={handleSubmission}>


                                                                <div className="email-compose-fields" data-select2-id={14}>



                                                                    <div className="form-group">
                                                                        <p htmlFor="exampleInputEmail1">Note: With this tool you can upload an Excel file with messages organised in columns, with each column containing a customized item specific to a receiver of the message. Please do not include headers for the columns, only include data that is to be sent in the message. </p>
                                                                    </div>







                                                                    <div className="dropify-wrapper">
                                                                        <div className="dropify-message">
                                                    <span className="file-icon">
                                                        <p>Drag and drop a file here or click here</p>
                                                    </span>
                                                                            <p className="dropify-error">Ooops, something wrong appended.</p>
                                                                        </div>
                                                                        <div className="dropify-loader" />
                                                                        <div className="dropify-errors-container"><ul />
                                                                        </div>
                                                                        <input type="file" id="myDropify" name={"myFile"} required={"required"} className="border" onChange={changeHandler}  accept={".xlsx"} />
                                                                        <button type="button" className="dropify-clear">Remove</button>
                                                                        <div className="dropify-preview">
                                                                            <span className="dropify-render" />
                                                                            <div className="dropify-infos">
                                                                                <div className="dropify-infos-inner">
                                                                                    <p className="dropify-filename">
                                                                                        <span className="file-icon" />
                                                                                        <span className="dropify-filename-inner" />
                                                                                    </p>
                                                                                    <p className="dropify-infos-message">Drag and drop or click to replace</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>







                                                                    <div className="mt-3">


                                                                        {




                                                                            IsSelected ?


                                                                                !creating ?
                                                                                    <button type={"submit"} className="btn btn-block btn-success mr-2 mb-2 mb-md-0 ">Upload List</button>

                                                                                    :
                                                                                    <button type={"button"} disabled={true} className="btn btn-block btn-success mr-2 mb-2 mb-md-0 text-white">   Uploading....</button>


                                                                                :<></>
                                                                        }




                                                                    </div>


                                                                </div>

                                                            </form>





                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                </div>

                            </div>
                        }


                        {

                            FileUploaded && <div className="col-lg-12 email-content">


                                <div className="email-list">
                                    <div className="row inbox-wrapper" data-select2-id={20}>
                                        <div className="col-lg-12" data-select2-id={19}>
                                            <div >
                                                <div >
                                                    <div className="row" data-select2-id={16}>

                                                        <div className="col-lg-12 email-content" data-select2-id={15}>




                                                            {
                                                                error.status_create_account && <Alert message={error.message} icon={error.icon} class_Name={error.class_Name} header={error.header}  showing={error.status_create_account}  handleStatus={handleStatusCreate} />
                                                            }


                                                            <form className="forms-sample" encType={"multipart/form-data"} onSubmit={submits}>


                                                                <div className="email-compose-fields" data-select2-id={14}>





                                                                    <div className="form-group">
                                                                        <p htmlFor="exampleInputEmail1"> Below is a sample of the uploaded data;  </p>
                                                                    </div>


                                                                    <div className="table-responsive pt-3" >
                                                                        <table className="table table-bordered table-dark">
                                                                            <thead>
                                                                            <tr>
                                                                            {
                                                                                Results.Header && Results.Header.map((result,i)=>(


                                                                                        <th className="p-3">#CUSTOM{i+1}#</th>



                                                                                ))
                                                                            }

                                                                            </tr>


                                                                            </thead>
                                                                            <tbody>


                                                                            {
                                                                                Results.Data && Results.Data.map((data,i)=>(


                                                                                    <tr>

                                                                                        {
                                                                                            Results.Header && Results.Header.map((result,j)=>(


                                                                                                <td className="p-3">{(data[j])}</td>



                                                                                            ))
                                                                                        }


                                                                                    </tr>

                                                                                ))
                                                                            }


                                                                            </tbody>
                                                                        </table>
                                                                    </div>






<div className={"row mt-2"}>

    <div className={"col-md-8"}>
        <div className="card">
            <div className="card-body">

                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">





                        <div className="card-body">
                            <h6 className="card-title">Send Message</h6>


                            {
                                error.status_login && <Alert message={error.message} icon={error.icon} class_Name={error.class_Name} header={error.header}  showing={error.status_login}  handleStatus={handleStatus} />
                            }






                                <div className="form-group">
                                    <label htmlFor="exampleInputText1">From</label>
                                    <input type="text" className="form-control" value={data.from} onChange={(e)=>
                                    { setData({...data,from:e.target.value})}
                                    } id="exampleInputText1"  placeholder="Maximum 11 characters" required={"required"} maxLength={"11"}/>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="exampleInputText1">To</label>
                                    <input type="text" className="form-control" value={data.to} onChange={(e)=>
                                    { setData({...data,to:e.target.value})}
                                    } id="exampleInputText1"  placeholder="recipient" required={"required"} />
                                </div>





                                <span className={"text-danger mt-2"}>As a requirement by UCC, all messages to Uganda unless registered will be appended with "DND *196#" (10 Characters)</span>

                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail3">Message</label>
                                    <textarea required={"required"} className="form-control" value={data.message}  onChange={(e)=>
                                    { setData({...data,message:e.target.value})}
                                    } placeholder="Type your message here!" id="exampleFormControlTextarea1" rows={5} defaultValue={""} />
                                </div>


                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword3">Schedule date</label>
                                    <input type="date" value={data.schedule_date}  onChange={(e)=>
                                    { setData({...data,schedule_date:e.target.value})}
                                    } className="form-control" id="exampleInputPassword3"  placeholder="Enter schedule date" />
                                </div>


                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword3">Schedule Time</label>
                                    <input type="time" value={data.schedule_time}  onChange={(e)=>
                                    { setData({...data,schedule_time:e.target.value})}
                                    } className="form-control" id="exampleInputPassword3"  placeholder="Enter schedule time" />
                                </div>




                                {

                                    !sending ?
                                        <button type={"submit"} className="btn btn-success  btn-space btn-block">Send Now</button>

                                        :
                                        <button type={"button"} disabled={true} className="btn  btn-success btn-space btn-block">   Sending....</button>

                                }





                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


    <div className={"col-md-4"}>
        <div className="card">

            <div className="email-head">
                <div className="email-head-title d-flex align-items-center">

                    Click to use custom field in message



                </div>
            </div>

            <div className="card-body">



                {
                    Results.Header && Results.Header.map((result,i)=>(


                        <button type={"button"} onClick={()=>{

                            if(!data.message)
                                data.message = ""
                            let custom = data.message+" "+"#CUSTOM"+(i+1)+'#';

                            setData({...data,message:custom})


                        }} className="btn btn-primary btn-block">#CUSTOM{i+1}#</button>



                    ))
                }
            </div>
        </div>
    </div>

</div>










                                                                </div>

                                                            </form>





                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                </div>

                            </div>
                        }










                    </div>
                </div>
            </div>
        </div>
    );
}
