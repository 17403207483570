import React, {useEffect, useState} from "react";
import utils from "../../Utils";
import {useLocation, useParams} from "react-router";
import {Link} from "react-router-dom";

export default ({change_state})=>{
    change_state('sent_messages');

    const [data,setData] = useState({});


    const [Data,setdata] = useState([])

    const [page,setpage] = useState(0)

    const [ListData,setListData] = useState(false)

    function useQuery() {

        return new URLSearchParams(useLocation().search);
    }


    let query = useQuery();


    const {message_id} = useParams();


    const load_data=(id)=>{


        {


            {


                fetch(utils.base_url + 'messaging/get_message_details/'+id,
                    {
                        method: 'POST',

                        headers: {'Content-Type': 'application/json'},

                    }).then(res => res.json())
                    .then(json => {


                        setListData(true)




                        if(json.length > 0 )
                        {



                            console.log(json);

                            let newData = Data

                            json.map(result=> {

                                newData = [...newData, result]

                            })


                            setdata(newData);





                        }

                    })
                    .catch((e) => {

                        console.log(e);

                    })
            }
        }

    }




    useEffect(()=>{



        let data = JSON.parse(query.get('details'));

         setData(data)

        load_data(message_id);


        //
        // setCreateAccount(data);
        //
        //
        // console.log('Edit users details');

    },[])

    const getTxstatus=(status)=>{

//        alert(status);

        switch (status) {

            case 0:
                return (<div className={"text-warning"}>Pending</div>)
                break;
            case 1:
                return (<div className={"text-success"}>Success</div>)
                break;
            case 2:
                return (<div className={"text-success"}>Declined</div>)
                break;

        }

    }





    let mailto = 'mailto:'+utils.support_email


    return (  <div className="col-lg-12 stretch-card">
        <div className="card">
            <div className="card-body ">

                <div className="col-md-12 main-panel bg-white">


                    {
                        data &&   <div className="main-panel-wrapper">
                            <h4 className="mt-2 font-weight-light mb-4">{


                                data.senderid
                                // JSON.stringify()

                            }

                                </h4>
                            <p className>{data.message}</p>
                            <p className><i className={"mdi mdi-clock mdi-16px m-2"}></i>{utils.getTimestring(data.schedule_date)}</p>
                            <p className><i className={"mdi mdi-account mdi-16px m-2"}></i>{query.get('user')}</p>
                            <hr className="my-5" id="introduction" />
                            <div className="grid-margin">
                                <h4 className="mb-4">Message details</h4>
                                <div className="table-responsive mt-3"  >

                                    {ListData &&

                                    <table className={"col-md-12 table-bordered"}>


                                        {
                                            ListData &&

                                            <thead>
                                            <tr>
                                                {/*<th className="pt-0">RefId</th>*/}
                                                <th className="p-2">DateTime</th>
                                                <th className="p-2">SenderId</th>
                                                <th className="p-2">Message</th>


                                                <th className="p-2">Receiver</th>

                                                <th className="p-2">Status</th>

                                                <th className="p-2">Charge</th>

                                                <th className="p-2">Route</th>

                                            </tr>
                                            </thead>


                                        }
                                        <tbody>

                                        {
                                            Data && Data.map(data => (



                                                <tr>


                                                    {/*<td>{data.id}</td>*/}
                                                    <td className="p-2">{utils.getTimestring(data.created_at)}</td>
                                                    <td className="p-2">{data.messageout.senderid}</td>
                                                    <td className="p-2">


                                                        {data.messageout.message}

                                                    </td>

                                                    <td className="p-2">{data.recipient} </td>
                                                    <td className="p-2">{getTxstatus(parseInt(data.status))}</td>


                                                    <td className="p-2"> {data.sms_cost}/-</td>
                                                    <td className="p-2"> {data.route && data.route.routeName}</td>


                                                </tr>


                                            ))
                                        }

                                        </tbody>
                                    </table>


                                    }
                                </div>
                            </div>





                        </div>
                    }



                </div>
            </div>
        </div>

    </div>);

}