import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import ListContactLists from "./ListContactLists";
import NewUser from "../Users/NewUser";
import NewList from "./NewList";
import ListContactDetails from "./ListContactDetails";


var urlencode = require('urlencode');
export default ({change_state, change_sublink})=> {

    change_state('contact_list');
    change_sublink('list_contacts');

    const [Data, setData] = useState([]);

    const {action} = useParams();
    // const {query} = parse(window.location.details);


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();



    useEffect(()=>{

        if(query.get('details')) {
            let data = JSON.parse(query.get('details'));
            setData(data);
        }


    },[])

    //
    // const getTitle=(link)=>{
    //
    //     switch (link) {
    //
    //         case "transaction_history":
    //
    //             return 'Transaction history';
    //
    //         case "tarrif_settings":
    //
    //             return 'Tariff settings';
    //
    //         case "top_up":
    //
    //             return 'Account Top Up';
    //
    //
    //         case "new_user":
    //             return 'Add new user';
    //
    //         case "list_accounts":
    //             return 'List users';
    //
    //
    //         case "edit_details":
    //             return 'Edit network details';
    //
    //
    //     }
    //
    //
    // }
    //

    const switching =()=>{

        switch (action) {



            case "list_contacts":

                return <ListContactLists />;

            case "new_list":

                return <NewList />;


            case "list_contact_details":

                return <ListContactDetails />;
            //
            // case "transaction_history":
            //
            //     return <TransactionHistory />;
            // case "top_up":
            //
            //     return <TopUp />;
            //
            // case "edit_details":
            //
            //     return <EditUser />;
            //
            //
            // case "new_user":
            //     return <NewUser/>;
            //
            //
            //
            // case "list_accounts":
            //     return <ListUsers/>;



        }
    }



    return (
        <div className="inbox-wrapper">
            <div >
                <div className="card">
                    <div className="card-body">



                        <div className="email-head">
                            <div className="email-head-title d-flex align-items-center">

                               Contact lists



                            </div>
                        </div>


                        <div className="row mt-2">
                            <div className="col-lg-3 email-aside border-sm-right">
                                <div className="aside-content">


                                    <div className="aside-nav ">
                                        <ul className="nav">






                                            <li >

                                                <Link to={"/contact_list/list_contacts"} className={"nav-link"} >



                                                    <button
                                                        className={action === 'list_contacts'?' btn btn-primary btn-block':' btn btn-outline-primary btn-block'}

                                                    >Existing lists</button>




                                                </Link>

                                            </li>



                                            <li >

                                                <Link to={"/contact_list/new_list"} className={"nav-link"} >



                                                    <button
                                                        className={action === 'new_list'?' btn btn-primary btn-block':' btn btn-outline-primary btn-block'}

                                                    >New List</button>




                                                </Link>

                                            </li>











                                        </ul>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 email-content">


                                <div className="email-list">

                                    {
                                        switching()
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}