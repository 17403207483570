import {HashRouter, Link} from "react-router-dom";

import '../../../assets/vendors/select2/select2.min.css'
import '../../../assets/vendors/simplemde/simplemde.min.css'
import {Helmet} from "react-helmet";
import React,{useState, useEffect} from "react";
import {Input} from "react-input";
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import Alert from "../../Alert";
import PasswordStrengthBar from "react-password-strength-bar";

export default ()=>{


    const [listName, setListName] = useState(true);
    const [passwordscore, setPasswordscore] = useState(0);
    const [creating,setCreating] = useState(false);
    const [create_account, setCreateAccount] = useState([]);
    const [data,setData] = useState({});
    const [sending,setSending] = useState(false);
    const [error,setError] = useState([]);

    // sublink('create_users')
    // change_state('users')



    const createAccount = e => {
        e.preventDefault()


        setSending(true);
        setCreating(true);


        fetch(utils.base_url+'user/create_user/'+ReactSession.get('id'), {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ create_account }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {

                setSending(false);
                setCreating(false);


                if(json.id)
                {


                    setError({
                        icon:'info',
                        header:'Thank you',
                        class_Name:'success',
                        status_create_account:true,
                        status:true,
                        message:'Account created successfully. Thank you!',
                    });


                    e.target.reset();



                }else{


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_create_account:true,
                        status:true,
                        message:json.ResultMessage,
                    });
                }


                console.log(json.ResultCode);
                console.log(json.ResultMessege);

                //setUser(json.user)

            })
            .catch((e)=>{
                setCreating(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    console.log(     ReactSession.get("username"));



    const getScore=(e)=>{
        setPasswordscore(e);
        console.log(e)   ;
    }


    const handleStatus=(status)=>{

        setError({...error,status_login:status});

    }


    const handleStatusCreate=(status)=>{

        setError({...error,status_create_account:status});

    }

    const changeHandler = (event) => {

        setSelectedFile(event.target.files[0]);

        setIsSelected(true);

    };

    const [IsSelected, setIsSelected] = useState();
    const [selectedFile, setSelectedFile] = useState();

    const [isFilePicked, setIsFilePicked] = useState(false);



    const handleSubmission = (e) => {

        e.preventDefault()

        const formData = new FormData();

        setCreating(true);
        formData.append('File', selectedFile);
        formData.append('list_name', JSON.stringify(listName));



        fetch(

            utils.base_url+"api/uploadForm/"+ReactSession.get('id'),

            {

                method: 'POST',

                body: formData,

            }

        )

            .then((response) => response.json())

            .then((result) => {

                setCreating(false);
                setIsSelected(false);


                setError({
                    icon:'info',
                    header:'Thank you',
                    class_Name:'success',
                    status_create_account:true,
                    status:true,
                    message:'Contact list uploaded successfully. Thank you!',
                });


                e.target.reset();

                console.log('Success:', result);

            })

            .catch((error) => {

                console.error('Error:', error);

            });

    };



    useEffect(()=>{

        console.log(data);

    },[data])

    return (

        <div className="row inbox-wrapper" data-select2-id={20}>
            <div className="col-lg-12" data-select2-id={19}>
                <div >
                    <div >
                        <div className="row" data-select2-id={16}>

                            <div className="col-lg-12 email-content" data-select2-id={15}>




                                {
                                    error.status_create_account && <Alert message={error.message} icon={error.icon} class_Name={error.class_Name} header={error.header}  showing={error.status_create_account}  handleStatus={handleStatusCreate} />
                                }


                                <form className="forms-sample" encType={"multipart/form-data"} onSubmit={handleSubmission}>


                                    <div className="email-compose-fields" data-select2-id={14}>



                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">List Name</label>
                                            <input name={"create-firstname"} type="text"  onChange={(e)=> setListName({...listName,Name:e.target.value})} required={"required"} className="form-control" placeholder="Name of the list" />
                                        </div>


                                        <div className="form-group">
                                            <p htmlFor="exampleInputEmail1">Note: With this tool you can upload an Excel file with messages organised in columns, with each column containing a customized item specific to a receiver of the message. Please do not include headers for the columns, only include data that is to be sent in the message. User <code>Column A for Name</code> and <code>Column B for phone Number </code> </p>
                                        </div>




                                        <div className="dropify-wrapper">
                                            <div className="dropify-message">
                                                    <span className="file-icon">
                                                        <p>Drag and drop a file here or click</p>
                                                    </span>
                                                <p className="dropify-error">Ooops, something wrong appended.</p>
                                            </div>
                                            <div className="dropify-loader" />
                                            <div className="dropify-errors-container"><ul />
                                            </div>
                                            <input type="file" id="myDropify" name={"myFile"} required={"required"} className="border" onChange={changeHandler}  accept={".xlsx"} />
                                            <button type="button" className="dropify-clear">Remove</button>
                                            <div className="dropify-preview">
                                                <span className="dropify-render" />
                                                <div className="dropify-infos">
                                                    <div className="dropify-infos-inner">
                                                        <p className="dropify-filename">
                                                            <span className="file-icon" />
                                                            <span className="dropify-filename-inner" />
                                                        </p>
                                                        <p className="dropify-infos-message">Drag and drop or click to replace</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>







                                        <div className="mt-3">


                                            {




                                                IsSelected ?


                                                !creating ?
                                                    <button type={"submit"} className="btn btn-block btn-success mr-2 mb-2 mb-md-0 ">Upload List</button>

                                                    :
                                                    <button type={"button"} disabled={true} className="btn btn-block btn-success mr-2 mb-2 mb-md-0 text-white">   Uploading....</button>


:<></>
                                            }




                                        </div>


                                    </div>

                                </form>





                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>


    );
}
