import React, {useEffect, useState} from "react";
import Alert from "../../Alert";
import NumberFormat from "react-number-format";
import utils from "../../Utils";
import {ReactSession} from "react-client-session";
import {useLocation} from "react-router";
import Swal from "sweetalert2";

export default ()=>{

    const [error,setError] = useState([]);
    const [data,setData] = useState({});
    const [sending,setSending] = useState(false);


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const handleStatus=(status)=>{

        setError({...error,status_login:status});

    }



    const submits = e => {
        e.preventDefault()


        console.log(JSON.stringify({ data }));



        setSending(true);


        fetch(utils.base_url+'user/account_balance_up/'+ReactSession.get('id'), {
            crossDomain:true,
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: { 'Content-Type': 'application/json' },
            // cache: false,
            // processData: false,
        })
            .then(res => res.json())
            .then(json => {



                console.log(json);
                setSending(false);


                if(parseInt(json.ResultCode) === 10001)
                {








                    setError({
                        icon:'bell',
                        header:'Thank you',
                        class_Name:'success',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });

                    e.target.reset();
                    setData({...data,amount:""})

                }else{


                    setError({
                        icon:'bell',
                        header:'Access denied',
                        class_Name:'danger',
                        status_login:true,
                        status:true,
                        message:json.ResultMessage,
                    });

                }




            })
            .catch((e)=>{
                // setSending(false);
                console.log(e);

            })
        // .then(json => setUser(json.user))
    };


    useEffect(()=>{

        let data = JSON.parse(query.get('details'));

        setData(data);

    },[]);


    return (<div>









        <div className="row profile-body">


            <div className="col-md-8 middle-wrapper border-sm-right">



                {
                    error.status_login && <Alert message={error.message} icon={error.icon} class_Name={error.class_Name} header={error.header}  showing={error.status_login}  handleStatus={handleStatus} />
                }


                <form className="forms-sample"  onSubmit={submits}>
                    <div className="row">
                        <div className="col-md-12 grid-margin">
                            <div className=" rounded">
                                <div className="card-header">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="ml-2">
                                                <p>Enter amount to credit</p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="description mt-1">
                                        <div className="form-group row py-0">
                                            <div className="col-md-12">




                                                <NumberFormat
                                                    required={"required"}
                                                    className={" form-control "}
                                                    placeholder="Amount to Topup"
                                                    value={data.amount}
                                                    onChange={(e)=>setData({...data,amount:e.target.value})}
                                                    thousandSeparator={','}
                                                    suffix={'/-'}
                                                />




                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex post-actions">
                                        <div className="form-group row pr-2 pl-2">



                                            <div className="col-md-12">
                                                <div className="form-group mb-0">






                                                    {

                                                        !sending ?
                                                            <button type={"submit"} className="btn btn-success  btn-space btn-block">Top up</button>

                                                            :
                                                            <button type={"button"} disabled={true} className="btn  btn-success btn-space btn-block">   Topping up....</button>

                                                    }



                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>

            <div className="d-none d-md-block col-md-4  left-wrapper">
                <div className=" rounded">
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <h6 className="card-title mb-0">First Name : </h6>

                        </div>
                        <p>{data.firstname}</p>

                        <div className="mt-3">
                            <label className="tx-11 font-weight-bold mb-0 text-uppercase">Last Name:</label>
                            <p className="text-muted">{data.lastname}</p>
                        </div>
                        <div className="mt-3">
                            <label className="tx-11 font-weight-bold mb-0 text-uppercase">Email:</label>
                            <p className="text-muted">{data.email}</p>
                        </div>
                        {/*<div className="mt-3">*/}
                            {/*<label className="tx-11 font-weight-bold mb-0 text-uppercase">Account Balance:</label>*/}
                            {/*<p className="text-muted">{parseFloat(data.account_balance).toLocaleString()}</p>*/}
                        {/*</div>*/}


                    </div>
                </div>
            </div>





        </div>












    </div>)

}