import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import NewUser from "./NewUser";
import ListUsers from "./ListUsers";
import { parse } from 'query-string';
import EditUser from "./EditUser";
import TopUp from "./TopUp";
import TransactionHistory from "./TransactionHistory";
import TarrifSettings from "./TarrifSettings";

var urlencode = require('urlencode');
export default ({change_state, change_sublink})=> {

    change_state('user_accounts');
    change_sublink('list_accounts');

    const [Data, setData] = useState([]);

    const {action} = useParams();
    // const {query} = parse(window.location.details);


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();



    useEffect(()=>{

        if(query.get('details')) {
            let data = JSON.parse(query.get('details'));
            setData(data);
        }


    },[])

    //
    // const getTitle=(link)=>{
    //
    //     switch (link) {
    //
    //         case "transaction_history":
    //
    //             return 'Transaction history';
    //
    //         case "tarrif_settings":
    //
    //             return 'Tariff settings';
    //
    //         case "top_up":
    //
    //             return 'Account Top Up';
    //
    //
    //         case "new_user":
    //             return 'Add new user';
    //
    //         case "list_accounts":
    //             return 'List users';
    //
    //
    //         case "edit_details":
    //             return 'Edit network details';
    //
    //
    //     }
    //
    //
    // }
    //

    const switching =()=>{

        switch (action) {



            case "tarrif_settings":

                return <TarrifSettings />;

            case "transaction_history":

                return <TransactionHistory />;
            case "top_up":

                return <TopUp />;

            case "edit_details":

                return <EditUser />;


            case "new_user":
                return <NewUser/>;



            case "list_accounts":
                return <ListUsers/>;



        }
    }



    return (
        <div className="inbox-wrapper">
    <div >
        <div className="card">
            <div className="card-body">



                <div className="email-head">
                    <div className="email-head-title d-flex align-items-center">

                         User Accounts

                        {

                            // query.get('details') ?Data.firstname+" "+Data.lastname+" - "+Data.email:' User Accounts'

                        }






                    </div>
                </div>


                <div className="row mt-2">
                    <div className="col-lg-3 email-aside border-sm-right">
                        <div className="aside-content">


                            <div className="aside-nav ">
                                <ul className="nav">


                                    {
                                        query.get('details') && <>


                                            <li >

                                                <Link to={"/user_accounts/top_up/?details="+urlencode(query.get('details'))} className={"nav-link"} >



                                                    <button
                                                        className={action === 'top_up'?' btn btn-primary btn-block':' btn btn-outline-primary btn-block'}

                                                    >Top up</button>




                                                </Link>

                                            </li>


                                            <li >

                                                <Link to={"/user_accounts/transaction_history/?details="+urlencode(query.get('details'))} className={"nav-link"} >



                                                    <button
                                                        className={action === 'transaction_history'?' btn btn-primary btn-block':' btn btn-outline-primary btn-block'}

                                                    >Transaction history</button>




                                                </Link>

                                            </li>

                                            <li >

                                                <Link to={"/user_accounts/tarrif_settings/?details="+urlencode(query.get('details'))} className={"nav-link"} >



                                                    <button
                                                        className={action === 'tarrif_settings'?' btn btn-primary btn-block':' btn btn-outline-primary btn-block'}

                                                    >Tariff settings</button>




                                                </Link>

                                            </li>





                                        </>
                                    }





                                    <li >

                                        <Link to={"/user_accounts/list_accounts"} className={"nav-link"} >



                                            <button
                                                className={action === 'list_accounts'?' btn btn-primary btn-block':' btn btn-outline-primary btn-block'}

                                            >User Accounts</button>




                                        </Link>

                                    </li>



                                    <li >

                                        <Link to={"/user_accounts/new_user"} className={"nav-link"} >



                                            <button
                                                className={action === 'new_user'?' btn btn-primary btn-block':' btn btn-outline-primary btn-block'}

                                            >Add User</button>




                                        </Link>

                                    </li>











                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 email-content">
                        {/*<div className="email-inbox-header">*/}
                            {/*<div className="row align-items-center">*/}
                                {/*<div className="col-lg-6">*/}
                                    {/*<div className="email-title mb-2 mb-md-0">*/}
                                        {/*{getTitle(action)}  </div>*/}
                                {/*</div>*/}

                            {/*</div>*/}
                        {/*</div>*/}

                        <div className="email-list">

                            {
                                switching()
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
)
}