import React,{useHistory} from 'react-router-dom';
import {ReactSession} from 'react-client-session';
import { createBrowserHistory } from 'history';

const Logout=()=>{



    ReactSession.setStoreType("localStorage");
    ReactSession.set('id',null);


     const history = createBrowserHistory();
    // //const history = useHistory();
     history.push('/');

    document.location.reload();



    return (<>
    </> );

}



export default Logout;